import React from "react";
import AsyncAutocompleteSelect from "./AsyncAutocompleteSelect";
import customersApi from "../../distflowAPI/customersApi";

/**
 * @param {Omit<
 *  import("./AsyncAutocompleteSelect").AsyncAutocompleteSelectProps<
 *    import("../../redux/module/customer").Customer
 *  >,
 *  'fetchOptions'
 * >} props
 */
const CustomerSelect = (props) => (
  <AsyncAutocompleteSelect
    fetchOptions={(searchTerm) => {
      console.log("current customers list is: ", props.customersList);
      console.log("supercharging with: ", props.supercharge);
      return customersApi
        .list(10, 1, [], [{ column: "name", value: searchTerm }])
        .then((res) => {
          const customers = res.results
            .filter((item) => {
              if (
                props.customersList &&
                props.customersList?.length > 0 &&
                props.customersList.map((it) => it.value).includes(item.id)
              ) {
                return false;
              }
              return true;
            })
            .map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            })
            .concat(props.supercharge || []);
          return (props.superchargeTop || []).concat(customers);
        });
    }}
    {...props}
  />
);

export default CustomerSelect;
