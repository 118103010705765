import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  OutlinedInput,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import DataTable from "../../../components/DataTable";
import { useSearchParams } from "react-router-dom";

import {
  formatAgGridFilters,
  formatAgGridSorting,
} from "../../../distflowAPI/common";
import { getNoPages, useSearchDebounce } from "../../../utils";
import PageLoading from "../../../components/PageLoading";
import {
  customerItems,
  customerItemsModify,
  getCustomers,
} from "../../../distflowAPI/customersApi";
import CustomerSelect from "../../../components/AsyncSelects/CustomerSelect";
import { smallButton } from "../../../utils/smallButton";
import { listVariants } from "../../../distflowAPI/ordersApi";
import { getVariants } from "../../../distflowAPI/products";
import Product from "../../manageAutoOrders/Product";
import HtmlTooltip from "../../../components/HtmlToolTip";
const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

const CustomersProductManagement = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    ordering: "internal_id",
    search: "",
  });
  const [customer, setCustomer] = useState({
    value: "all",
    label: "ALL",
  });
  const [customers, setCustomers] = useState([]);
  const [customersLoading, setCustomersLoading] = useState(false);
  const [filterBy, setFilterBy] = useState([]);
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useSearchDebounce(200);
  const [filterAvailability, setFilterAvailability] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [openAddItem, setOpenAddItem] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [noPages, setNoPages] = useState(1);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  function addItem(variantId) {
    setIsAdding(true);
    customerItems(1, 1, customer?.value, "ADD", variantId)
      .then((res) => {
        refreshTable();
      })
      .finally(() => {
        setIsAdding(false);
      });
  }
  function refreshTable() {
    setCustomersLoading(true);
    if (customer?.value) {
      console.log("customer is set to: ", customer);
      customerItems(
        page,
        pageSize,
        customer?.value,
        "LIST",
        null,
        search,
        filterAvailability
      ).then((res) => {
        setTimeout(() => {
          setCustomersLoading(false);
        }, 100);
        console.log("res is: ", res);
        setProducts(res.data.results);
        setNoPages(res.data.pages);
      });
    }
  }
  useEffect(() => {
    refreshTable();
  }, [customer]);

  useEffect(() => {
    console.log("search changed");
    if (page != 1) {
      setPage(1);
    } else {
      refreshTable();
    }
  }, [search, filterAvailability]);

  useEffect(() => {
    if (page != 1) {
      setPage(1);
    } else {
      refreshTable();
    }
  }, [pageSize]);
  useEffect(() => {
    refreshTable();
  }, [page]);

  useEffect(() => {
    setSearch(searchParams.get("search"));
    setFilterAvailability(searchParams.get("availability"));
  }, [searchParams]);
  const handleChange = (field, value) => {
    if (field === "orderBy") {
      handleSortParams(value);
    }
  };
  function handleSortParams(value) {
    let newSortingValue = "";
    if (value?.length > 0) {
      newSortingValue = value[0]?.field;
      if (value[0]?.sort != "asc") {
        newSortingValue = "-" + newSortingValue;
      }
    }
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.set("ordering", newSortingValue);
      return newParams;
    });
  }

  const columns = [
    {
      headerName: "name",
      field: "name",
      wrapText: true,
      autoHeight: true,
      sortable: true,
      comparator: () => {}, //disable default sorting
      flex: 2,
      cellStyle: { fontSize: 14 },
    },
    {
      headerName: "sku",
      field: "sku",
      wrapText: true,
      autoHeight: true,
      sortable: true,
      comparator: () => {}, //disable default sorting
      flex: 1,

      cellStyle: { fontSize: 14 },
    },
    {
      headerName: "vendor",
      field: "vendor",
      wrapText: true,
      autoHeight: true,
      sortable: true,
      comparator: () => {}, //disable default sorting
      flex: 1,

      cellStyle: { fontSize: 14 },
    },
    {
      headerName: "category",
      field: "category",
      wrapText: true,
      autoHeight: true,
      sortable: true,
      comparator: () => {}, //disable default sorting
      flex: 1,
      cellStyle: { fontSize: 14 },
    },
    {
      headerName: "Availability",
      wrapText: true,
      autoHeight: true,
      sortable: true,
      comparator: () => {}, //disable default sorting
      flex: 1,
      cellStyle: { fontSize: 14 },
      cellRenderer: (params) => (
        <AvailabilityRenderer params={params} customer={customer} />
      ),
    },
    {
      headerName: "Actions",
      field: "",
      wrapText: true,
      autoHeight: true,
      sortable: false,
      comparator: () => {}, //disable default sorting
      flex: 1,
      cellStyle: { fontSize: 14 },
      cellRenderer: (params) => (
        <Button
          variant="contained"
          color="error"
          sx={{ ...smallButton }}
          onClick={() => {
            setConfirmOpen(params.data);
          }}
          disabled={isDeleting}
        >
          delete
        </Button>
      ),
    },
  ];

  return (
    <>
      <PageLoading dataLoading={customersLoading} />

      <Typography
        fontSize="23px"
        fontWeight={800}
        component="h2"
        data-testid="page-title"
      >
        Customers Product Visibility Management{" "}
        {customer?.value && <>- {customer.label}</>}
      </Typography>
      <Stack direction={"row"} spacing={1.5} my="25px">
        <CustomerSelect
          superchargeTop={[{ value: "all", label: "ALL" }]}
          label="Customer"
          name="customer"
          required
          value={customer}
          onChange={(_e, newValue) => setCustomer(newValue)}
          //sx={{ maxWidth: 600, maxHeight: 10 }}
          extraStyles={{ maxWidth: 500 }}
        />
        <OutlinedInput
          sx={{ height: "40px" }}
          name="Search"
          placeholder="Search"
          fullWidth
          value={searchParams.get("search")}
          onChange={(e) => {
            setSearchParams((params) => {
              const newParams = new URLSearchParams(params);
              newParams.set("search", e.target.value);
              return newParams;
            });
          }}
        />
        {customer?.value == "all" && (
          <Box sx={{ minWidth: "40%" }}>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography>Availability: </Typography>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={(searchParams.get("availability") || "").includes(
                    "everyoneonly"
                  )}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSearchParams((params) => {
                        const newParams = new URLSearchParams(params);
                        newParams.set("availability", "everyoneonly");
                        return newParams;
                      });
                    } else {
                      setSearchParams((params) => {
                        const newParams = new URLSearchParams(params);
                        newParams.set(
                          "availability",
                          (searchParams.get("availability") || "").replace(
                            "everyoneonly",
                            ""
                          )
                        );
                        return newParams;
                      });
                    }
                  }}
                />
                <Typography>Everyone</Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={(searchParams.get("availability") || "").includes(
                    "specificonly"
                  )}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSearchParams((params) => {
                        const newParams = new URLSearchParams(params);
                        newParams.set("availability", "specificonly");
                        return newParams;
                      });
                    } else {
                      setSearchParams((params) => {
                        const newParams = new URLSearchParams(params);
                        newParams.set(
                          "availability",
                          (searchParams.get("availability") || "").replace(
                            "specificonly",
                            ""
                          )
                        );
                        return newParams;
                      });
                    }
                  }}
                />
                <Typography>Specific</Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={(searchParams.get("availability") || "").includes(
                    "everyoneandspecific"
                  )}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSearchParams((params) => {
                        const newParams = new URLSearchParams(params);
                        newParams.set("availability", "everyoneandspecific");
                        return newParams;
                      });
                    } else {
                      setSearchParams((params) => {
                        const newParams = new URLSearchParams(params);
                        newParams.set(
                          "availability",
                          (searchParams.get("availability") || "").replace(
                            "everyoneandspecific",
                            ""
                          )
                        );
                        return newParams;
                      });
                    }
                  }}
                />
                <Typography>Everyone and Specific</Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"}>
                <Checkbox
                  checked={searchParams.get("availability") == ""}
                  onChange={(e) => {
                    setSearchParams((params) => {
                      const newParams = new URLSearchParams(params);
                      newParams.set("availability", "");
                      return newParams;
                    });
                  }}
                />
                <Typography>All</Typography>
              </Stack>
            </Stack>
          </Box>
        )}

        <Button
          onClick={() => {
            setOpenAddItem(true);
          }}
          variant="contained"
          sx={{ minWidth: "10%" }}
        >
          Add Item
        </Button>
      </Stack>

      <Box className="table-wrapper">
        <DataTable
          rowData={customer?.value ? products : []}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          suppressSorting={true} //disable default sorting
          rowHeight={53}
          headerHeight={60}
          loading={customersLoading}
          onSortChanged={(e) => {
            handleChange(
              "orderBy",
              formatAgGridSorting(e.columnApi.columnModel.gridColumns)
            );
          }}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          noPages={noPages}
        />
      </Box>
      <AddVariantModal
        open={openAddItem}
        setOpen={(val) => setOpenAddItem(val)}
        onConfirm={(id) => {
          addItem(id);
        }}
        existing_items={products}
      />
      <ConfirmDeleteItem
        item={confirmOpen}
        setOpen={setConfirmOpen}
        customer={customer}
        refreshTable={refreshTable}
      />
    </>
  );
};

export default CustomersProductManagement;

function AvailabilityRenderer({ params, customer }) {
  const [toolTipOpen, setToolTipOpen] = useState(false);
  return (
    <Stack direction={"row"} alignItems={"center"} spacing={0}>
      {params.data.available_to_all &&
        params.data.can_be_ordered_by &&
        params.data.can_be_ordered_by.length == 0 &&
        customer?.value == "all" && <Typography>Everyone</Typography>}
      {params.data.available_to_all && customer?.value != "all" && (
        <Typography>Everyone</Typography>
      )}
      {params.data.available_to_all &&
        params.data.can_be_ordered_by &&
        params.data.can_be_ordered_by.length > 0 && (
          <>
            <Typography>Everyone and</Typography>
            <HtmlTooltip
              open={toolTipOpen}
              onClose={() => setToolTipOpen(false)}
              //onOpen={() => setToolTipOpen(true)}
              title={
                <>
                  <Stack>
                    {params.data.can_be_ordered_by.map((c) => (
                      <Typography>{c.name}</Typography>
                    ))}
                  </Stack>
                </>
              }
            >
              <Button onClick={() => setToolTipOpen(!toolTipOpen)}>
                {params.data.can_be_ordered_by.length}{" "}
                {params.data.can_be_ordered_by.length > 1
                  ? "customers"
                  : "customer"}
              </Button>
            </HtmlTooltip>
          </>
        )}
      {!params.data.available_to_all &&
        params.data?.can_be_ordered_by &&
        customer?.value == "all" && (
          <>
            <Typography>Only to</Typography>
            <HtmlTooltip
              open={toolTipOpen}
              onClose={() => setToolTipOpen(false)}
              //onOpen={() => setToolTipOpen(true)}
              title={
                <>
                  <Stack>
                    {params.data.can_be_ordered_by.map((c) => (
                      <Typography>{c.name}</Typography>
                    ))}
                  </Stack>
                </>
              }
            >
              <Button onClick={() => setToolTipOpen(!toolTipOpen)}>
                {params.data.can_be_ordered_by.length}{" "}
                {params.data.can_be_ordered_by.length > 1
                  ? "customers"
                  : "customer"}
              </Button>
            </HtmlTooltip>
          </>
        )}
      {!params.data.available_to_all && customer?.value != "all" && (
        <>
          <Typography>Specific</Typography>
        </>
      )}
    </Stack>
  );
}
function AddVariantModal({ open, setOpen, onConfirm, existing_items }) {
  const [errors, setErrors] = useState({});
  const [variants, setVariants] = useState([]);
  const [totalPages, setTotalPages] = useState(2);
  const [productsPage, setProductsPage] = useState(1);
  const [filterVariant, setFilterVariant] = useState("");
  const [toHide, setToHide] = useState([]);
  function cleanup() {
    setToHide([]);
    setVariants([]);
    setFilterVariant("");
  }
  console.log("existing items: ", existing_items);
  function updateVariantsList() {
    const existing_ids = existing_items.map((item) => item.id);
    getVariants(
      20,
      productsPage,
      [],
      [{ value: filterVariant, column: "search" }]
    ).then((res) => {
      console.log("VARIANTS RES IS: ", res);
      const newVariants = res.data.results.map((v) => {
        const item = {
          ...v,
          label: v.name,
          ordered: false,
          ordered_pc: false,
          vendor_details: {
            vendorName: v.vendor,
          },
        };
        return item;
      });
      setVariants(newVariants);
      setTotalPages(Math.ceil(res.count / 10));
    });
  }

  useEffect(() => {
    updateVariantsList();
  }, [filterVariant]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        cleanup();
        setOpen(false);
      }}
      maxWidth={850}
    >
      <DialogTitle>Add item to visible products</DialogTitle>
      <DialogContent>
        <Stack padding={2} spacing={1}>
          <TextField
            label="Search"
            value={filterVariant}
            onChange={(e) => setFilterVariant(e.target.value)}
          />
          <Box overflow={"auto"} maxHeight={550} maxWidth={550}>
            <Stack spacing={1}>
              {variants
                .filter((item) => !toHide.includes(item?.id))
                .map((item, index) => (
                  <Product
                    parent
                    item={item}
                    //account={data.order.account}
                    // add item modal
                    customAdd={(data) => {
                      onConfirm(data?.item?.id);
                      setToHide([...toHide, data?.item?.id]);
                    }}
                    showView
                  />
                ))}
            </Stack>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              cleanup();
              setOpen(false);
            }}
          >
            Close
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

function ConfirmDeleteItem({ item, customer, setOpen, refreshTable }) {
  const [customersToKeep, setCustomersToKeep] = useState(new Set());
  const [isDeleting, setIsDeleting] = useState(false);
  function handleDelete() {
    setIsDeleting(true);
    const payload = {
      operation: "DELETE",
      customer: customer.value,
      item: item.id,
      keep_customers: Array.from(customersToKeep),
    };
    console.log("payload is: ", payload);
    customerItemsModify(payload).then(() => {
      refreshTable();
      setCustomersToKeep(new Set());
      setTimeout(() => {
        setIsDeleting(false);
        setOpen(false);
      }, 500);
    });
  }
  function handleClose() {
    setCustomersToKeep(new Set());
    setOpen(false);
  }
  return (
    <Dialog open={item} onClose={() => handleClose()}>
      {item && (
        <>
          <DialogTitle>
            Are you sure you want to delete <b>{item?.name}</b> from{" "}
            <b>{customer.label}</b>
          </DialogTitle>
          <DialogContent>
            <Typography>
              You're about to revoke access from <b>{customer?.label}</b>
            </Typography>
            {customer?.value == "all" &&
              item?.can_be_ordered_by &&
              item?.can_be_ordered_by.length > 0 && (
                <Stack>
                  <Typography>
                    This item is also available individually to the following{" "}
                    {item.can_be_ordered_by.length} customers
                  </Typography>
                  <Typography marginBottom={2}>
                    Select <b>only</b> the customers you want to{" "}
                    <b>keep access</b> to the item
                  </Typography>
                  <Stack direction={"row"} spacing={2}>
                    <Button
                      onClick={() =>
                        setCustomersToKeep(
                          new Set(item.can_be_ordered_by.map((c) => c.id))
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button onClick={() => setCustomersToKeep(new Set())}>
                      Deselect All ({customersToKeep.size})
                    </Button>
                  </Stack>
                  {item.can_be_ordered_by.map((c) => (
                    <Stack direction={"row"} alignItems={"center"}>
                      <Checkbox
                        checked={customersToKeep.has(c.id)}
                        onChange={(e) => {
                          const newSet = new Set(customersToKeep);
                          if (e.target.checked) {
                            newSet.add(c.id);
                            setCustomersToKeep(newSet);
                          } else {
                            newSet.delete(c.id);
                            setCustomersToKeep(newSet);
                          }
                        }}
                      />
                      <Typography>{c.name}</Typography>
                    </Stack>
                  ))}
                </Stack>
              )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleClose()}
              variant="contained"
              color="error"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => handleDelete()}
              disabled={isDeleting}
            >
              Delete
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
