import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Block from "../../../components/Block";
import {
  Grid,
  Stack,
  Box,
  Typography,
  Input,
  OutlinedInput,
  Button,
} from "@mui/material";

function itemPriority(items) {
  const result = items.map((item, index) => {
    return { ...item, priority: index };
  });
  return result;
}

const Item = styled(Paper)(({ theme, isSelected }) => ({
  padding: theme.spacing(1),
  margin: theme.spacing(1),
  backgroundColor: isSelected ? "#e3f2fd" : theme.palette.background.paper,
  border: isSelected
    ? `1px solid #2196f3`
    : `1px solid ${theme.palette.divider}`,
  transition: "all 0.2s ease",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: isSelected ? "#bbdefb" : theme.palette.action.hover,
  },
}));

function AccountsDnD({
  accounts,
  routeData,
  setRouteData,
  accountSearch,
  setAccountSearch,
}) {
  const [movedItems, setMovedItems] = useState([]);
  const [items, setItems] = useState({
    accounts: accounts.filter(
      (account) =>
        !routeData.route_accounts.map((rv) => rv.id).includes(account.id)
    ),
    route_accounts: routeData.route_accounts,
  });

  // Add state for selected item
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    setItems({
      accounts: accounts.filter(
        (account) =>
          !routeData.route_accounts.map((rv) => rv.id).includes(account.id)
      ),
      route_accounts: routeData.route_accounts,
    });
    // Clear selection when data changes
    setSelectedItem(null);
  }, [accounts, routeData]);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = items[source.droppableId];
    const finish = items[destination.droppableId];

    if (start === finish) {
      // gotta reorder
      const newItems = Array.from(start);
      const [removed] = newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, removed);
      if (source.droppableId == "route_accounts") {
        setRouteData({ ...routeData, route_accounts: itemPriority(newItems) });
      }

      return;
    }

    // Moving from one list to another
    const startItems = Array.from(start);
    const [removed] = startItems.splice(source.index, 1);
    const newStart = { ...items, [source.droppableId]: startItems };

    const finishItems = Array.from(finish);
    finishItems.splice(destination.index, 0, removed);
    const newFinish = { ...items, [destination.droppableId]: finishItems };

    const newState = {
      ...items,
      [source.droppableId]: newStart[source.droppableId],
      [destination.droppableId]: newFinish[destination.droppableId],
    };
    if (source.droppableId == "accounts") {
      setRouteData({
        ...routeData,
        route_accounts: newFinish[destination.droppableId],
      });
    }
    if (source.droppableId == "route_accounts") {
      setRouteData({
        ...routeData,
        route_accounts: newStart[source.droppableId],
      });
    }
  };

  // Handle item click in route_accounts list
  const handleRouteItemClick = (item, index) => {
    if (selectedItem === null) {
      // First click - select the item
      setSelectedItem({ item, index, source: "route_accounts" });
    } else {
      // Second click - place the item
      if (
        selectedItem.source === "route_accounts" &&
        selectedItem.item.id === item.id &&
        selectedItem.index === index
      ) {
        // Clicked the same item - deselect it
        setSelectedItem(null);
        return;
      }

      // Create a new copy of the route_accounts array
      const newRouteAccounts = [...items.route_accounts];

      if (selectedItem.source === "route_accounts") {
        // Moving within route_accounts - remove from original position first
        newRouteAccounts.splice(selectedItem.index, 1);

        // Determine target index (after the clicked item)
        // We need to adjust the index if the destination is after the source
        let targetIndex = index;
        if (index > selectedItem.index) {
          targetIndex = index - 1; // Adjust for the removed item
        }

        // Insert at new position (after the clicked item)
        newRouteAccounts.splice(targetIndex + 1, 0, selectedItem.item);
        setMovedItems([...movedItems, selectedItem.item]);
      } else if (selectedItem.source === "accounts") {
        // Moving from accounts to route_accounts
        // Insert at the position after the clicked item
        newRouteAccounts.splice(index + 1, 0, selectedItem.item);
        setMovedItems([...movedItems, selectedItem.item]);
        // We would need to also update the accounts list, but that will happen in the next render
        // due to the useEffect watching routeData
      }

      // Update route data with the new ordering
      setRouteData({
        ...routeData,
        route_accounts: itemPriority(newRouteAccounts),
      });

      // Reset selection
      setSelectedItem(null);
    }
  };

  // Handle item click in accounts list
  const handleAccountItemClick = (item, index, source = null) => {
    if (
      selectedItem &&
      selectedItem.source === "accounts" &&
      source == "accounts"
    ) {
      setSelectedItem({ item, index, source: "accounts" });
    }
    if (selectedItem === null) {
      // First click - select the item
      setSelectedItem({ item, index, source: "accounts" });
    } else if (
      selectedItem.source === "accounts" &&
      selectedItem.item.id === item.id &&
      selectedItem.index === index
    ) {
      // Clicked the same item - deselect it
      setSelectedItem(null);
      return;
    }
  };

  // Handle moving selected account to route_accounts
  const handleMoveToRoute = () => {
    if (selectedItem && selectedItem.source === "accounts") {
      // Add the selected account to the end of route_accounts
      const routeItems = [...items.route_accounts];
      routeItems.push(selectedItem.item);

      // Update route data
      setRouteData({ ...routeData, route_accounts: itemPriority(routeItems) });
      setMovedItems([...movedItems, selectedItem.item]);
      // Reset selection
      setSelectedItem(null);
    }
  };

  // Handle moving selected route_account back to accounts
  const handleRemoveFromRoute = () => {
    if (selectedItem && selectedItem.source === "route_accounts") {
      const routeItems = [...items.route_accounts];
      const [removed] = routeItems.splice(selectedItem.index, 1);

      // Update route data
      setRouteData({ ...routeData, route_accounts: itemPriority(routeItems) });

      // Reset selection
      setSelectedItem(null);
    }
  };

  // Reset selection
  const handleCancelSelection = () => {
    setSelectedItem(null);
  };

  return (
    <Block>
      <Typography component="p" variant="label" mb="11px">
        Accounts
      </Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid
          container
          columnSpacing={{ xs: 1, sm: 3, xl: "10px" }}
          margin="0px 0 0"
        >
          <Grid
            item
            md={8}
            lg={8}
            sx={{ maxWidth: "908px", width: "100%", minHeight: 520 }}
          >
            {!selectedItem && (
              <Typography sx={{ position: "absolute", top: 60 }}>
                Click an item to start arranging
              </Typography>
            )}
            {selectedItem && (
              <Stack
                direction={"row"}
                spacing={1}
                alignItems={"center"}
                sx={{ position: "absolute", top: 60 }}
              >
                <Typography variant="body2">
                  Selected: <strong>{selectedItem.item.name}</strong>{" "}
                  {selectedItem.source === "route_accounts"
                    ? "- Click where to place it or use buttons below"
                    : "- Click 'Add to Route' button to add"}
                </Typography>
                {selectedItem.source === "route_accounts" && (
                  <Button
                    size="small"
                    color="error"
                    variant="outlined"
                    onClick={handleRemoveFromRoute}
                    sx={{ mr: 1 }}
                  >
                    Remove from Route
                  </Button>
                )}
                {selectedItem.source === "accounts" && (
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={handleMoveToRoute}
                    sx={{ mr: 1 }}
                  >
                    Add to Route
                  </Button>
                )}
                <Button
                  size="small"
                  color="inherit"
                  variant="outlined"
                  onClick={handleCancelSelection}
                >
                  Cancel
                </Button>
              </Stack>
            )}
            <div
              style={{
                width: "100%",
                textAlign: "left",
                fontSize: "12px",
                minHeight: 200,
                maxHeight: 450,
                height: "100%",
                padding: "5px",
                borderRadius: "5px",
                overflowY: "auto",
                border: "1px solid #e0e0e0",
                marginTop: 85,
              }}
            >
              {items.route_accounts.map((item, index) => (
                <Item
                  key={item.id}
                  isSelected={
                    selectedItem?.source === "route_accounts" &&
                    selectedItem?.item.id === item.id
                  }
                  onClick={() => handleRouteItemClick(item, index)}
                >
                  <Typography
                    fontWeight={
                      movedItems.map((m) => m.id).includes(item?.id) ? 600 : 400
                    }
                  >
                    {item.name}
                  </Typography>
                </Item>
              ))}
            </div>
          </Grid>
          <Grid item md={4} lg={4} sx={{ maxWidth: "908px", width: "100%" }}>
            <div style={{ padding: "8px" }}>
              <Typography variant="inputLabel" component="p">
                Search Accounts
              </Typography>
              <OutlinedInput
                fullWidth
                name="accountSearch"
                value={accountSearch}
                onChange={(e) => setAccountSearch(e.target.value)}
              />
            </div>

            <Box
              style={{
                width: "100%",
                textAlign: "left",
                fontSize: "12px",
                minHeight: 200,
                maxHeight: 450,
                height: "100%",
                padding: "5px",
                borderRadius: "5px",
                overflowY: "auto",
                border: "1px solid #e0e0e0",
              }}
            >
              {items.accounts.slice(0, 15).map((item, index) => (
                <Item
                  key={item.id}
                  isSelected={
                    selectedItem?.source === "accounts" &&
                    selectedItem?.item.id === item.id
                  }
                  onClick={() =>
                    handleAccountItemClick(item, index, "accounts")
                  }
                >
                  <Typography
                    fontWeight={
                      movedItems.map((m) => m.id).includes(item?.id) ? 800 : 400
                    }
                  >
                    {item.name}
                  </Typography>
                </Item>
              ))}
            </Box>
          </Grid>
        </Grid>
      </DragDropContext>
    </Block>
  );
}

export default AccountsDnD;
