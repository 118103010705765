import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Block from "../../../components/Block";
import { useNavigate, useParams } from "react-router-dom";
import { listVendors } from "../../../distflowAPI/vendorsApi";
import { listAccounts } from "../../../distflowAPI/accountsApi";
import Rdnd from "./rDnD";
import {
  createRoute,
  getRoute,
  updateRoute,
} from "../../../distflowAPI/routesTwo";
import AccountsDnD from "./accountsDnD";
import {
  fireErrorNotification,
  fireSuccessNotification,
  useSearchDebounce,
} from "../../../utils";
import DriverSelect from "../../../components/AsyncSelects/DriverSelect";
import ConfirmDialog from "../../../components/ConfirmDialog";
import RouteChangesForm from "./RouteChangesForm";
import { removeUser } from "../../../redux/module/user";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { deleteRoute } from "../../../redux/module/route";
import PageLoading from "../../../components/PageLoading";
import MultipleEmailsForm from "../../../components/shared/MultipleEmailsForm";
import {
  createRouteEmail,
  deleteRouteEmail,
  listRouteEmails,
  updateRouteEmail,
} from "../../../distflowAPI/routesApi";

const DAYS_OPTIONS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "sunday",
];

function itemPriority(items) {
  const result = items.map((item, index) => {
    return { ...item, priority: index };
  });
  return result;
}

function normalizeVendors(vendors) {
  const result = vendors.map((vendor) => {
    return {
      // ...vendor,
      vendor: vendor.id,
      id: vendor.id,
      name: vendor.name,
      address: vendor.address,
    };
  });
  return result;
}

function normalizeAccounts(accounts) {
  const result = accounts.map((account) => {
    return {
      // ...vendor,
      account: account.id,
      id: account.id,
      name: account.name,
      territory: account.territory.name,
    };
  });
  return result;
}

function sortDataBy(data, byKey) {
  let sortedData;
  sortedData = data.sort(function (a, b) {
    return a[byKey] - b[byKey];
  });
  return sortedData;
}

const initialData = {
  name: "",
  driver: null,
  route_vendors: [],
  route_accounts: [],
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  sunday: false,
  note: "",
  allow_multiple_accounts: false,
};

const AddEditRoute2 = ({ isChanges }) => {
  const dispatch = useAppDispatch();

  const [accountSearchDB, setAccountSearchDB] = useSearchDebounce();
  const [vendorSearchDB, setVendorSearchDB] = useSearchDebounce();
  const [accountSearch, setAccountSearch] = useState("");
  const [vendorSearch, setVendorSearch] = useState("");
  useEffect(() => setAccountSearchDB(accountSearch), [accountSearch]);
  useEffect(() => setVendorSearchDB(vendorSearch), [vendorSearch]);
  const navigate = useNavigate();
  const { id } = useParams();
  //general data
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [routeData, setRouteData] = useState(initialData);
  const [isRouteLoading, setIsRouteLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [isOpenDeleteEmail, setIsOpenDeleteEmail] = useState(false);

  const [email, setEmail] = useState({
    email: "",
    id: null,
  });
  const setValue = (name, value) => {
    setRouteData({ ...routeData, [name]: value });
  };

  const getRouteFunction = (routeId) => {
    setIsRouteLoading(true);
    getRoute(routeId)
      .then((res) => {
        const newData = res.data;
        setSelectedDriver({
          label: `${newData.driver.user.first_name} ${newData.driver.user.last_name}`,
          id: newData.driver.id,
        });
        newData.driver = newData.driver.id;
        newData.route_vendors = sortDataBy(
          newData.route_vendors.map((item) => {
            return {
              ...item,
              id: item.vendor,
            };
          }),
          "priority"
        );
        newData.route_accounts = sortDataBy(
          newData.route_accounts.map((item) => {
            return {
              ...item,
              id: item.account,
            };
          }),
          "priority"
        );

        // save selected data on route changes form if we create new "change"
        if (isChanges && id === "new") {
          newData.change_for = routeData.change_for;
          newData.change_start = routeData.change_start;
          newData.change_end = routeData.change_end;
        }

        setRouteData(newData);
      })
      .finally(() => {
        setIsRouteLoading(false);
      });
  };

  useState(() => {
    if (!isNaN(parseInt(id))) {
      getRouteFunction(id);
    }
  }, [id]);

  useEffect(() => {
    if (isChanges && routeData.change_for?.id && id === "new") {
      getRouteFunction(routeData.change_for.id);
    }
  }, [routeData.change_for]);

  // driver section
  const [selectedDriver, setSelectedDriver] = useState({ label: "" });

  // vendors section
  const [vendors, setVendors] = useState([]);
  useEffect(() => {
    listVendors(100, 1, vendorSearch).then((res) =>
      setVendors(itemPriority(normalizeVendors(res.results)))
    );
  }, [vendorSearchDB]);

  // accounts section
  const [accounts, setAccounts] = useState([]);
  useEffect(() => {
    listAccounts(100, 1, accountSearch).then((res) =>
      setAccounts(itemPriority(normalizeAccounts(res.data.results)))
    );
  }, [accountSearchDB]);

  // api post
  function handleSubmit() {
    setErrors({});
    setIsSubmitting(true);
    setSuccessMessage("");
    const payload = { ...routeData };
    payload.route_vendors = payload.route_vendors.map((rv) => {
      return { ...rv, route: payload.id };
    });
    console.log("route data is: ", routeData);
    if (isChanges) {
      payload["change_for"] = routeData.change_for.id;
    }

    if (!isNaN(parseInt(id))) {
      updateRoute(id, payload).then(
        (res) => {
          fireSuccessNotification("Route updated successfully!");
          setIsSubmitting(false);
        },
        (error) => {
          setIsSubmitting(false);
          const errorFields = Object.keys(error.response.data);
          errorFields.forEach((field) => {
            fireErrorNotification(error.response.data[field][0]);
          });
        }
      );
    } else {
      createRoute(payload).then(
        (res) => {
          fireSuccessNotification("Route created successfully!");
          navigate(`/routes2/routes/${res.data.id}`);
        },
        (error) => {
          setIsSubmitting(false);
          const errorFields = Object.keys(error.response.data);
          errorFields.forEach((field) => {
            fireErrorNotification(error.response.data[field][0]);
          });
        }
      );
    }
  }

  const cleanSearchFields = () => {
    setAccountSearch("");
    setVendorSearch("");
  };
  const [isOpenDiscard, setIsOpenDiscard] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const handleDiscard = () => {
    if (!isNaN(parseInt(id))) {
      getRouteFunction(id);
    } else {
      setRouteData(initialData);
      setSelectedDriver({ label: "" });
    }
    cleanSearchFields();
  };

  const handleDelete = async () => {
    const resp = await dispatch(deleteRoute(id));
    if (resp.payload === id) {
      navigate(-1);
    }
  };

  const handleAddEmail = (newEmail, callback) => {
    createRouteEmail(id, newEmail).then((res) => {
      setEmails([{ email: res.data.email, id: res.data.id }, ...emails]);
      callback();
    });
  };

  const handleUpdateEmail = async (emailId, updatedEmail, callback) => {
    updateRouteEmail(emailId, updatedEmail).then(() => {
      fetchEmails();
      callback();
    });
  };

  function handleDeleteEamil(emailId, email) {
    setEmail({ email, id: emailId });
    setIsOpenDeleteEmail(true);
  }

  function handleDeleteEamil_() {
    deleteRouteEmail(email.id).then(() => {
      setEmails(emails.filter((e) => e.id !== email.id));
    });
  }

  const fetchEmails = async () => {
    listRouteEmails(id).then((res) => {
      setEmails(res);
    });
  };

  useEffect(() => {
    fetchEmails();
  }, []);

  return (
    <Box maxWidth="1394px" margin=" 30px auto 0">
      <PageLoading dataLoading={isRouteLoading} />

      <ConfirmDialog
        title="Discard updates?"
        open={isOpenDiscard}
        setOpen={setIsOpenDiscard}
        onConfirm={handleDiscard}
      >
        {`Are you sure you want to discard all changes?`}
      </ConfirmDialog>
      <ConfirmDialog
        title="Delete Email?"
        open={isOpenDeleteEmail}
        setOpen={setIsOpenDeleteEmail}
        onConfirm={handleDeleteEamil_}
      >
        {`Are you sure you want to delete "${email.email}"?`}
      </ConfirmDialog>
      <ConfirmDialog
        title="Delete route?"
        open={isOpenDelete}
        setOpen={setIsOpenDelete}
        onConfirm={handleDelete}
      >
        {`Are you sure you want to delete this route?`}
      </ConfirmDialog>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="space-between"
        spacing="20px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={{ xs: "space-between", sm: "flex-start" }}
          sx={{ width: { xs: "100%", sm: "initial" }, gap: "20px" }}
        >
          <Button
            className="btn-height"
            onClick={() =>
              navigate(
                isChanges ? "/routes2/routes-changes" : "/routes2/routes"
              )
            }
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Typography
            variant="pageTitle"
            data-testid="page-title"
            component="p"
            sx={{
              maxWidth: "350px",
            }}
          >
            {routeData.name}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          flexWrap="wrap"
          sx={{
            gap: "11px",
            "@media(max-width: 480px)": {
              width: "100%",
              button: { width: "100%" },
            },
          }}
        >
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={() => setIsOpenDelete(true)}
          >
            Delete
          </Button>
          <Button
            className="btn-height"
            variant="outlined"
            onClick={() => setIsOpenDiscard(true)}
          >
            Discard
          </Button>
          <Button
            className="btn-height"
            color="black"
            variant="contained"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Save & continue Editing
          </Button>
        </Stack>
      </Stack>
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 3, xl: "50px" }}
        margin="20px 0 0"
      >
        <Grid item md={12} lg={12} sx={{ maxWidth: "908px", width: "100%" }}>
          {successMessage && <Alert>{successMessage}</Alert>}

          {isChanges && (
            <RouteChangesForm
              routeData={routeData}
              setValue={setValue}
              disabled={id !== "new"}
            />
          )}

          <Grid
            container
            columnSpacing={{ xs: 1, sm: 3, xl: 3 }}
            margin="20px 0 0"
            alignItems="stretch"
          >
            <Grid
              item
              md={8}
              lg={8}
              sx={{ maxWidth: "908px", width: "100%", mb: 3 }}
            >
              <Block sx={{ height: "100%", padding: 2 }}>
                <Stack
                  sx={{
                    display: "grid",
                    gap: "20px",
                    gridTemplateColumns: "1fr 1fr",
                  }}
                >
                  <Box>
                    <Typography component="p" variant="label" mb={1}>
                      Name
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Route's name"
                      data-testid="test-name-id"
                      value={routeData.name}
                      onChange={(e) => {
                        setValue("name", e.target.value);
                      }}
                      required
                    />
                  </Box>
                  <Box>
                    <Typography component="p" variant="label" mb={1}>
                      Driver
                    </Typography>
                    <DriverSelect
                      name="driver"
                      placeholder="Driver's name"
                      fullWidth
                      value={selectedDriver}
                      onChange={(_event, newValue) => {
                        setSelectedDriver(null);
                        setValue("driver", null);
                        if (newValue) {
                          if (newValue.value) {
                            setSelectedDriver(newValue);
                            setValue("driver", newValue.value);
                            //setVendor(newValue.value)
                          }
                        }
                      }}
                    />
                  </Box>
                </Stack>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    mt: 2,
                  }}
                >
                  <Typography component="p" variant="label" mb={-2}>
                    Emails
                  </Typography>

                  <MultipleEmailsForm
                    emails={emails}
                    setEmails={setEmails}
                    addEmail={handleAddEmail}
                    updateEmail={handleUpdateEmail}
                    deleteEmail={handleDeleteEamil}
                  />
                </Box>
              </Block>
            </Grid>
            <Grid
              item
              md={4}
              lg={4}
              sx={{ maxWidth: "908px", width: "100%", mb: 3 }}
            >
              <Block sx={{ height: "100%", padding: 2 }}>
                <Typography component="p" variant="label" mb="11px">
                  Schedule
                </Typography>
                <Grid
                  container
                  columnSpacing={{ xs: 1, sm: 3, xl: "5px" }}
                  margin="2px 0 0"
                >
                  {DAYS_OPTIONS.map((day) => (
                    <Grid
                      item
                      md={6}
                      lg={6}
                      sx={{ maxWidth: "908px", width: "100%" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={routeData[day]}
                            onChange={(e) => setValue(day, e.target.checked)}
                            name={day}
                          />
                        }
                        label={day}
                      />
                    </Grid>
                  ))}
                  <Grid
                    item
                    md={12}
                    lg={12}
                    sx={{ maxWidth: "908px", width: "100%" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={routeData.allow_multiple_accounts}
                          onChange={(e) =>
                            setValue(
                              "allow_multiple_accounts",
                              e.target.checked
                            )
                          }
                          name={"Allow accounts from different routes"}
                        />
                      }
                      label={"Allow accounts from different routes"}
                    />
                  </Grid>
                </Grid>
              </Block>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        columnSpacing={{ xs: 1, sm: 3, xl: "10px" }}
        margin="0px 0 0"
      >
        {routeData.routes_to_pick_up_from &&
          routeData.routes_to_pick_up_from.length > 0 && (
            <Grid
              item
              md={12}
              lg={12}
              sx={{ maxWidth: "908px", width: "100%" }}
            >
              <Block>
                <Typography component="p" variant="label" mb="11px">
                  Pick Up from routes
                </Typography>
                <Box>
                  {routeData.routes_to_pick_up_from.map((rr) => (
                    <Box sx={{}}>
                      <Typography>
                        Pick up from {rr.pick_up_from.vendor.name}{" "}
                        <b>Through</b>{" "}
                        <a href={`/routes2/routes/${rr.pick_up_from.route.id}`}>
                          {rr.pick_up_from.route.name}
                        </a>
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Block>
            </Grid>
          )}
        <Grid item md={12} lg={12} sx={{ maxWidth: "908px", width: "100%" }}>
          <Rdnd
            vendors={vendors}
            routeData={routeData}
            setRouteData={setRouteData}
            vendorSearch={vendorSearch}
            setVendorSearch={setVendorSearch}
            getRouteFunction={getRouteFunction}
          />
        </Grid>
        <Grid item md={12} lg={12} sx={{ maxWidth: "908px", width: "100%" }}>
          <AccountsDnD
            accounts={accounts}
            routeData={routeData}
            setRouteData={setRouteData}
            accountSearch={accountSearch}
            setAccountSearch={setAccountSearch}
          />
        </Grid>
        <Grid item md={12} lg={12} sx={{ maxWidth: "908px", width: "100%" }}>
          <Block>
            <Typography component="p" variant="label" mb="11px">
              Notes
            </Typography>
            <TextField
              rows="4"
              data-testid="test-note-id"
              multiline
              value={routeData.note || ""}
              onChange={(e) => setValue("note", e.target.value)}
              fullWidth
            />
          </Block>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddEditRoute2;
