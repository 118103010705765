/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  acknowledgeAllNotification,
  acknowledgeNotification,
  listNotifications,
  notificationsCount,
} from "../../distflowAPI/notifications";
import DataTable from "../../components/DataTable";
import { formatAgGridSorting } from "../../distflowAPI/common";
import { getNoPages } from "../../utils";
import { useSearchParams } from "react-router-dom";
import NotificationsFilter from "../../components/Notifications/Filter";
import columns from "../../components/Notifications/NotificationColumns";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  FireTruck,
  LocalShipping,
  LocalShippingOutlined,
  Store,
  StoreOutlined,
} from "@mui/icons-material";
import Block from "../../components/Block";

const cardStyle = {
  bgcolor: "#fff",
  padding: "20px",
  border: "1px solid #E0E0E0",
  borderRadius: "5px",
};
dayjs.extend(relativeTime);

function NotificationTabs() {
  const [selectedTab, setSelectedTab] = useState("customerPortal");
  return (
    <Block>
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            aria-label="lab API tabs example"
            variant="scrollable"
            onChange={(e, v) => setSelectedTab(v)}
          >
            <Tab
              key={"customerPortal"}
              icon={<StoreOutlined />}
              value={"customerPortal"}
              iconPosition="start"
              label={"customer Portal"}
            />
            <Tab
              key={"drivers"}
              icon={<LocalShippingOutlined />}
              value={"drivers"}
              iconPosition="start"
              label={"Drivers"}
            />
          </TabList>
        </Box>
        <TabPanel value="drivers">
          <Notification notificationType={"drivers"} />
        </TabPanel>
        <TabPanel value="customerPortal">
          <Notification notificationType={"customerPortal"} />
        </TabPanel>
      </TabContext>
    </Block>
  );
}
function Notifications() {
  return <NotificationTabs />;
}
function Notification({ notificationType }) {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uncknowledgedNotificationCount, setUncknowledgedNotificationCount] =
    useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [orderBy, setOrderBy] = useState([]);
  const [count, setCount] = useState(0);
  const [searchParams] = useSearchParams({ acknowledged: "true" });

  const fetchNotifications = async () => {
    setLoading(true);

    await listNotifications(
      notificationType == "drivers" ? true : false,
      page,
      pageSize,
      orderBy,
      searchParams
    )
      .then((response) => {
        setNotifications(response.data.results);
        setCount(response.data.count);
      })
      .catch((error) => {
        console.log(error);
      });

    setLoading(false);
  };

  const fetchNotificationsCount = async () => {
    await notificationsCount(notificationType == "drivers" ? "true" : "false")
      .then((response) => {
        setUncknowledgedNotificationCount(response.data.count);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setNotificationAsAcknowledged = async (notificationId) => {
    await acknowledgeNotification(notificationId)
      .then(() => {
        fetchNotifications();
        fetchNotificationsCount();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setAllNotificationsAsAcknowledged = async () => {
    acknowledgeAllNotification().then(() => {
      fetchNotifications();
      fetchNotificationsCount();
    });
  };

  const RowColumns = columns(setNotificationAsAcknowledged);

  useEffect(() => {
    fetchNotifications();
  }, [page, pageSize, orderBy, searchParams]);

  useEffect(() => {
    fetchNotificationsCount();
  }, []);

  return (
    <>
      <Box sx={cardStyle}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography
              fontSize="18px"
              fontWeight={600}
              component="h3"
              data-testid="page-title"
            >
              Notifications
            </Typography>
            <Typography
              fontSize="14px"
              fontWeight={500}
              component="p"
              data-testid="description"
              color={"#6E6E6E"}
            >
              Unacknowledged Notifications: {uncknowledgedNotificationCount}
            </Typography>
          </Box>
          <Button
            className="btn-height"
            variant="contained"
            onClick={() => {
              setAllNotificationsAsAcknowledged();
            }}
          >
            Acknowledge All
          </Button>
        </Stack>

        <NotificationsFilter />

        <Stack my="20px">
          <DataTable
            rowData={notifications}
            loading={loading}
            setPageSize={setPageSize}
            columnDefs={RowColumns}
            onSortChanged={(event) =>
              setOrderBy(
                formatAgGridSorting(event.columnApi.columnModel.gridColumns)
              )
            }
            page={page}
            pageSize={pageSize}
            noPages={getNoPages(count, pageSize)}
            setPage={setPage}
          />
        </Stack>
      </Box>
    </>
  );
}

export default Notifications;
