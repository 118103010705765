import { Button, Typography, Stack, TextField } from "@mui/material";
import Block from "../../../components/Block";
import { useState, useEffect } from "react";
import { updatePaymentGroup } from "../../../distflowAPI/paymentsApi";

function PaymentNotes({ payment, id }) {
  const [note, setNote] = useState("");
  const [editNote, setEditNote] = useState(payment.notes ? false : true);
  async function updateNote() {
    const data = updatePaymentGroup(id, {
      notes: note,
    });
    if (id) {
      setEditNote(false);
      setNote(note);
      console.log("set note to: ", note);
    }
  }
  useEffect(() => {
    setNote(payment.notes);
    if (payment.notes) {
      setEditNote(false);
    }
  }, [payment]);
  return (
    <Block>
      <Stack>
        <>
          <Typography fontWeight={600}>Notes:</Typography>
          {editNote ? (
            <>
              <TextField
                rows={5}
                fullWidth
                data-testid="test-note-id"
                multiline
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              <Stack direction={"row"} marginTop={"5px"}>
                <Button
                  color="success"
                  variant="contained"
                  sx={{ marginRight: "5px" }}
                  onClick={() => updateNote()}
                >
                  Save
                </Button>
                <Button
                  onClick={() => setEditNote(false)}
                  color="error"
                  variant="contained"
                >
                  Cancel
                </Button>
              </Stack>
            </>
          ) : (
            <>
              <Typography sx={{ whiteSpace: "pre-line" }}>{note}</Typography>
              <Stack justifyContent={"end"} direction={"row"}>
                <Button
                  onClick={() => setEditNote(true)}
                  sx={{ maxWidth: "120px", padding: "3px 4px" }}
                  color="info"
                  variant="contained"
                >
                  Update Note
                </Button>
              </Stack>
            </>
          )}
        </>
      </Stack>
    </Block>
  );
}

export default PaymentNotes;
