import {
  Typography,
  Box,
  Stack,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import { useEffect, useState } from "react";
import Block from "../../components/Block";
import DatePickerCustom2 from "../../components/DatePicker2";
import dayjs from "dayjs";
import { AgGridReact } from "ag-grid-react";
import { getOrdersStatistics } from "../../distflowAPI/ordersApi";
import { downloadFile } from "../../distflowAPI/downloadFile";
import groupsApi from "../../distflowAPI/groupsApi";
function ProfitAndLoss() {
  const [startDate, setStartDate] = useState(dayjs().subtract(7, "days"));
  const [endDate, setEndDate] = useState(dayjs());
  const [asOfToday, setAsOfToday] = useState(true);
  return (
    <Box maxWidth="1525px" margin=" 30px auto 0">
      <Stack spacing={3}>
        <ProfitAndLossConfig
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          asOfToday={asOfToday}
          setAsOfToday={setAsOfToday}
        />
      </Stack>
    </Box>
  );
}

function ProfitAndLossConfig(props) {
  const [typesOpen, setTypesOpen] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [accountTypes, setAccountTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAccountTypes, setSelectedAccountTypes] = useState({
    NONE: false,
  });
  useEffect(() => {
    setIsLoading(true);
    groupsApi.list(100, 1, [], [], null).then((res) => {
      const newSelected = { ...selectedAccountTypes };
      setAccountTypes([{ id: "NONE", name: "No Group" }, ...res.results]);
      res.results.map((acc) => {
        newSelected[acc.id] = false;
      });
      setSelectedAccountTypes(newSelected);
      setIsLoading(false);
    });
  }, []);
  return (
    <Stack>
      <Block>
        <Typography marginBottom={2}>Profit and Loss Report config</Typography>
        <Dialog open={typesOpen} onClose={() => setTypesOpen(false)}>
          <DialogTitle>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography>Select account types</Typography>
              <Stack direction={"row"} spacing={5}>
                <Button
                  onClick={() => {
                    const newSelected = { ...selectedAccountTypes };
                    Object.keys(newSelected).map((k) => {
                      newSelected[k] = true;
                    });
                    setSelectedAccountTypes(newSelected);
                  }}
                  sx={{ maxWidth: 80 }}
                >
                  Select All
                </Button>
                <Button
                  onClick={() => {
                    const newSelected = { ...selectedAccountTypes };
                    Object.keys(newSelected).map((k) => {
                      newSelected[k] = false;
                    });
                    setSelectedAccountTypes(newSelected);
                  }}
                  sx={{ maxWidth: 80 }}
                >
                  DeSelect All
                </Button>
              </Stack>
            </Stack>
          </DialogTitle>
          <DialogContent>
            {isLoading && <Typography>Loading...</Typography>}
            <Grid container>
              {accountTypes.map((acc) => (
                <Grid item md={6}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    alignContent={"center"}
                    alignItems={"center"}
                  >
                    <Checkbox
                      onChange={(e) => {
                        setSelectedAccountTypes({
                          ...selectedAccountTypes,
                          [acc.id]: e.target.checked,
                        });
                      }}
                      checked={selectedAccountTypes[acc.id]}
                    />
                    <Typography>{acc.name}</Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setTypesOpen(false)}
              variant="contained"
              color="error"
            >
              Close
            </Button>

            <Button
              variant="contained"
              color="primary"
              disabled={isExporting || isLoading}
              onClick={() => {
                setIsExporting(true);
                downloadFile(
                  `/api/orders/statistics_grouped/?start_date=${props.startDate.format(
                    "YYYY-MM-DD"
                  )}&end_date=${props.endDate.format("YYYY-MM-DD")}&selectedTypes=${Object.keys(
                    selectedAccountTypes
                  )
                    .filter((k) => selectedAccountTypes[k])
                    .map((k) => k)}`,
                  "items_statistics.csv"
                ).finally(() => {
                  setIsExporting(false);
                  setTypesOpen(false);
                });
              }}
            >
              Export
            </Button>
          </DialogActions>
        </Dialog>
        <Stack direction={"row"} spacing={3}>
          <DatePickerCustom2
            label={"Start Date"}
            controlledValue={props.startDate}
            setControlledValue={props.setStartDate}
          />
          <DatePickerCustom2
            label={"End Date"}
            controlledValue={props.endDate}
            setControlledValue={props.setEndDate}
          />
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Checkbox
              checked={props.asOfToday}
              onChange={(e) => props.setAsOfToday(e.target.checked)}
            />
            <Typography>As of today</Typography>
          </Stack>

          <Button
            variant="contained"
            onClick={() =>
              downloadFile(
                `/api/orders/statistics/?start_date=${props.startDate.format(
                  "YYYY-MM-DD"
                )}&end_date=${props.endDate.format("YYYY-MM-DD")}`,
                "items_statistics.csv"
              )
            }
          >
            Items
          </Button>
          <Button variant="contained" onClick={() => setTypesOpen(true)}>
            Items/Customer
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              downloadFile(
                `/api/accounting/invoice_statistics/?start_date=${props.startDate.format(
                  "YYYY-MM-DD"
                )}&end_date=${props.endDate.format("YYYY-MM-DD")}&as_of_today=${props.asOfToday}`,
                "invoices_statistics.csv"
              )
            }
          >
            Invoices
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              downloadFile(
                `/api/accounting/invoice_statistics_by_customer/?start_date=${props.startDate.format(
                  "YYYY-MM-DD"
                )}&end_date=${props.endDate.format("YYYY-MM-DD")}&as_of_today=${props.asOfToday}`,
                "invoices_statistics_by_customer.csv"
              )
            }
          >
            Customers
          </Button>
        </Stack>
      </Block>
      <Block>
        <LossTable start_date={props.startDate} end_date={props.endDate} />
      </Block>
    </Stack>
  );
}

function ProfitAndLossSummary() {}

function ProfitTable() {}

function LossTable({ start_date, end_date }) {
  const [data, setData] = useState([]);
  useEffect(() => {
    return;
    getOrdersStatistics(
      start_date.format("YYYY-MM-DD"),
      end_date.format("YYYY-MM-DD"),
      "vendors_report"
    ).then((res) => setData(res.data.data));
  }, [start_date, end_date]);

  return (
    <div style={{ width: "100%" }} className="ag-theme-alpine">
      <AgGridReact
        animateRows
        domLayout="autoHeight"
        rowData={data}
        columnDefs={[
          {
            headerName: "date",
            field: "date",
            sortable: true,
          },
          {
            headerName: "Product",
            field: "name",
            sortable: true,
          },
          {
            headerName: "SKU",
            field: "sku",
            sortable: true,
          },
          {
            headerName: "Vendor",
            field: "vendor",
            sortable: true,
          },
          {
            headerName: "total cost",
            field: "total_cost",
            sortable: true,
            cellRenderer: (params) => `$${params.data.total_cost}`,
          },
          {
            headerName: "quantity sold",
            field: "delivered_qty",
            sortable: true,
          },
          {
            headerName: "total sales",
            field: "total_price",
            sortable: true,
            cellRenderer: (params) => `$${params.data.total_price}`,
          },
        ]}
        //defaultColDef={defaultColDef}
        suppressSorting={true} //disable default sorting
        rowHeight={33}
        headerHeight={42}
      />
    </div>
  );
}

export default ProfitAndLoss;

/*

  IDEA

  <i---- header ------i>
  <start date> <end date>
  
  <part one: summary>
  <total orders> <total delivered> <total delivered> <total cost> <total income> <total upcoming income>

  <part two>
  <Table 1>
    <summary of cost by vendor>
  </table 1>
  <Table 2>
    <summary of income by account>
  </table 2>

*/
