import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Alert,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Block from "../../components/Block";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Separator from "../../components/Separator";
import dayjs from "dayjs";
import { DATE_FORMAT_FRONT } from "../../utils/constants";
import PageLoading from "../../components/PageLoading";
import {
  attachPaymentFile,
  deletePaymentAttachment,
  getPayment,
  issueRefund,
  voidPayment,
} from "../../distflowAPI/paymentsApi";
import { smallButton } from "../../utils/smallButton";
import { downloadFile } from "../../distflowAPI/downloadFile";
import Reconciliations from "./paymentDetailComponents/reconciliations";
import PaymentNotes from "./paymentDetailComponents/paymentNotes";
import useAppSelector from "../../hooks/useAppSelector";
import HistoryModal from "../invoices/components/HistoryModal";
import { useModal } from "mui-modal-provider";

const PAYMENT_METHOD = {
  CREDIT_CARD: "Credit Card",
  CREDIT_CARD_REFUND: "CC refund",
  CHEQUE: "CHECK",
  QUICK_PAY: "Quick Pay",
  WIRE_TRANSFER: "Wire Transfer",
  CASH: "Cash",
  ACH: "ACH",
  CUSTOMER_CREDIT: "Customer credit",
  BARTER: "Barter",
};

function getPaymentMethod(pm) {
  return PAYMENT_METHOD[pm] || pm;
}

const PaymentDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { currentRoute, previousRoute } = useAppSelector(
    (state) => state.utils
  );
  const [payment, setPayment] = useState({
    payments: [],
    transactions: [],
    reconciliations: [],
    attachments: [],
  });

  const [backButtonLink, setBackButtonLink] = useState("");

  function fetchData() {
    if (id) {
      getPayment(id).then((res) => setPayment(res.data));
    }
  }
  useEffect(() => {
    setBackButtonLink(currentRoute.pathname + currentRoute.search);
  }, []);
  useEffect(() => {
    fetchData();
  }, [id]);
  const [openAttach, setOpenAttach] = useState(false);
  function getFileName(rl) {
    return rl.split("?")[0].split("/").reverse()[0];
  }
  const [paymentToVoid, setPaymentToVoid] = useState(false);
  const [ccToRefund, setCcToRefund] = useState(false);
  const { showModal } = useModal();
  return (
    <Box maxWidth="1600px" margin=" 30px auto 0">
      <AttachFile
        open={openAttach}
        setOpen={setOpenAttach}
        paymentId={id}
        fetchData={fetchData}
      />
      <RefundCc
        ccToRefund={ccToRefund}
        setCcToRefund={setCcToRefund}
        fetchData={fetchData}
      />
      <VoidPayment
        paymentToVoid={paymentToVoid}
        setPaymentToVoid={setPaymentToVoid}
        fetchData={fetchData}
      />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="space-between"
        spacing="20px"
        mb={5}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={{ xs: "space-between", sm: "flex-start" }}
          sx={{ width: { xs: "100%", sm: "initial" }, gap: "20px" }}
        >
          <Button
            // onClick={() => navigate("/invoices/payments")}
            onClick={() => navigate(backButtonLink)}
            variant="outlined"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Typography variant="pageTitle" noWrap component="p">
            Payment #{id}
          </Typography>
          <Button
            variant="contained"
            onClick={() =>
              downloadFile(`/api/accounting/payment_groups/${id}/download/`)
            }
          >
            Download
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              showModal(HistoryModal, {
                paymentGroupId: id,
                title: "Payment History",
              })
            }
          >
            History
          </Button>
        </Stack>
      </Stack>
      <Grid container columnSpacing={{ xs: 1, sm: "20px" }} margin="36px 0 0">
        <Grid item width="100%" xl={10} marginBottom={2} marginLeft={10}>
          <Block>
            <Grid container>
              <Grid item width="100%" xl={6}>
                <Typography fontSize="23px" fontWeight={600} mb="0px">
                  ROYAL KOSHER FOODS
                </Typography>
              </Grid>
              <Grid item width="100%" xl={6} alignItems={"end"}>
                <Typography fontSize="23px" fontWeight={600} align="right">
                  PAYMENT
                </Typography>
              </Grid>
            </Grid>
            <Separator />
            <Grid container marginBottom={0}>
              <Grid item width="100%" md={12}>
                <Stack
                  direction={"row"}
                  gap={"10px"}
                  justifyContent={"space-between"}
                >
                  <Typography fontSize="16px" fontWeight={450} align="left">
                    Payment No: {payment.id}
                  </Typography>
                  <Typography fontSize="16px" fontWeight={450} mb="0px">
                    Date:{" "}
                    {dayjs.utc(payment.payment_date).format(DATE_FORMAT_FRONT)}
                  </Typography>
                  <Typography
                    fontSize="16px"
                    fontWeight={450}
                    mb="0px"
                    align="right"
                  >
                    Amount: ${payment.total}
                  </Typography>
                  <Typography
                    fontSize="16px"
                    fontWeight={450}
                    mb="0px"
                    align="right"
                  >
                    Amount Paid: ${payment.paid}
                  </Typography>
                  <Typography
                    fontSize="16px"
                    fontWeight={450}
                    mb="0px"
                    align="right"
                  >
                    Balance: ${payment.remaining}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Separator />
            <Grid container>
              <Grid item md={12}>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid md={12}>
                    <Typography fontSize="16px" fontWeight={400} mb="0px">
                      Payment level:{" "}
                      <b>{payment.account ? "Account" : "Customer"}</b>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item width="100%" xl={6}>
                {payment.account && (
                  <>
                    <Typography fontSize="16px" fontWeight={400} mb="0px">
                      {payment.account.name}
                    </Typography>
                    <Typography fontSize="16px" fontWeight={400} mb="0px">
                      {payment.account.address.street}
                    </Typography>
                    <Typography fontSize="16px" fontWeight={400} mb="0px">
                      {payment.account.address.city},{" "}
                      {payment.account.address.state}{" "}
                      {payment.account.address.zip}
                    </Typography>
                  </>
                )}
                {payment.customer && (
                  <>
                    <Typography fontSize="16px" fontWeight={400} mb="0px">
                      {payment.customer.name}
                    </Typography>
                    <Typography fontSize="16px" fontWeight={400} mb="0px">
                      {payment.customer.address.street}
                    </Typography>
                    <Typography fontSize="16px" fontWeight={400} mb="0px">
                      {payment.customer.address.city},{" "}
                      {payment.customer.address.state}{" "}
                      {payment.customer.address.zip}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid
                item
                width="100%"
                xl={6}
                alignItems={"end"}
                justifyContent={"end"}
              >
                <Typography
                  fontSize="16px"
                  fontWeight={400}
                  mb="0px"
                  align="right"
                >
                  ROYAL KOSHER FOODS
                </Typography>
                <Typography
                  fontSize="16px"
                  fontWeight={400}
                  mb="0px"
                  align="right"
                >
                  4309-14TH AVE
                </Typography>
                <Typography
                  fontSize="16px"
                  fontWeight={400}
                  mb="0px"
                  align="right"
                >
                  Brooklyn, NY 11219
                </Typography>
              </Grid>
            </Grid>
            <Separator />
            <Typography fontSize={24} marginBottom={2}>
              Invoices
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography fontSize="16px" fontWeight={600} mb="0px">
                      Invoice
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontSize="16px" fontWeight={600} mb="0px">
                      Invoice Date
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      mb="0px"
                      align="left"
                    >
                      Invoice Total
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      mb="0px"
                      align="left"
                    >
                      Allocated Amount
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      mb="0px"
                      align="left"
                    >
                      Total Paid
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      mb="0px"
                      align="left"
                    >
                      Balance
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      mb="0px"
                      align="left"
                    >
                      Memo
                    </Typography>
                  </TableCell>
                </TableRow>
                {payment.payments.map((payment_item) => (
                  <TableRow>
                    <TableCell>
                      <Typography fontSize="16px" fontWeight={400} mb="0px">
                        <a
                          href={`/invoices/invoices/${payment_item.invoice.id}`}
                          target="_blank"
                        >
                          #{payment_item.invoice.id}
                        </a>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="16px" fontWeight={400} mb="0px">
                        {payment_item.invoice.date}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="16px" fontWeight={400} mb="0px">
                        ${payment_item.invoice.total_amount}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography
                        fontSize="16px"
                        fontWeight={400}
                        mb="0px"
                        align="left"
                      >
                        ${payment_item.amount}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="16px" fontWeight={400} mb="0px">
                        ${payment_item.invoice.total_paid}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="16px" fontWeight={400} mb="0px">
                        ${payment_item.invoice.balance}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="16px" fontWeight={400} mb="0px">
                        {payment_item.invoice.notes || "-"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Typography
              fontSize="16px"
              fontWeight={400}
              mb="0px"
              mt="12px"
              align="right"
              mr="12px"
            >
              <b>Total:</b> $
              {`${payment.payments
                .reduce((total, invoice) => total + Number(invoice.amount), 0)
                .toFixed(2)}`}
            </Typography>
            {payment.ramaining > 0 && (
              <Typography
                fontSize="16px"
                fontWeight={400}
                mb="0px"
                mt="12px"
                align="right"
                mr="12px"
              >
                <b>Credit:</b> ${payment.remaining}
              </Typography>
            )}
            <Separator />
            <Typography fontSize={24} marginBottom={2}>
              Transactions
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      mb="0px"
                      align="left"
                    >
                      ID
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      mb="0px"
                      align="left"
                    >
                      Method
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      mb="0px"
                      align="left"
                    >
                      Amount
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      mb="0px"
                      align="left"
                    >
                      Fees
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      mb="0px"
                      align="left"
                    >
                      Refrence
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      fontSize="16px"
                      fontWeight={600}
                      mb="0px"
                      align="left"
                    >
                      Memo
                    </Typography>
                  </TableCell>
                </TableRow>
                {payment?.transactions?.map((transaction) => (
                  <TableRow>
                    <TableCell>
                      <Typography
                        fontSize="16px"
                        fontWeight={600}
                        mb="0px"
                        align="left"
                      >
                        #{transaction.id}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="16px" fontWeight={400} mb="0px">
                        {PAYMENT_METHOD[transaction.method] ||
                          transaction.method}
                        {transaction.method != "CUSTOMER_CREDIT" &&
                          !transaction.voided_invoice && (
                            <Button
                              sx={{
                                ...smallButton,
                                maxHeight: 20,
                                maxWidth: 30,
                                marginLeft: 2,
                              }}
                              variant="contained"
                              color="error"
                              onClick={() => {
                                setPaymentToVoid(transaction);
                              }}
                            >
                              VOID
                            </Button>
                          )}
                        {transaction.voided_invoice && (
                          <Typography>
                            VOIDED{" "}
                            <a
                              href={`/invoices/invoices/${transaction.voided_invoice}`}
                            >
                              RECONCILIATION #{transaction.voided_invoice}
                            </a>
                          </Typography>
                        )}
                        {transaction.method == "CREDIT_CARD" &&
                          !transaction.voided_invoice &&
                          transaction.amount_paid > 0 && (
                            <Button
                              variant="contained"
                              sx={{
                                ...smallButton,
                                maxHeight: 20,
                                maxWidth: 100,
                                marginLeft: 2,
                              }}
                              onClick={() => {
                                console.log("set cc to refund: ", transaction);
                                setCcToRefund(transaction);
                              }}
                            >
                              Issue Refund
                            </Button>
                          )}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="16px" fontWeight={400} mb="0px">
                        ${transaction.amount_paid}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="16px" fontWeight={400} mb="0px">
                        ${transaction.fee}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="16px" fontWeight={400} mb="0px">
                        {transaction.reference || "-"}{" "}
                        {transaction.method == "CREDIT_CARD" && (
                          <span>CC DIGITS: {transaction.cc_digits}</span>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontSize="16px" fontWeight={400} mb="0px">
                        {transaction.notes || "-"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Typography
              fontSize="16px"
              fontWeight={400}
              mb="0px"
              mt="12px"
              align="right"
              mr="12px"
            >
              <b>Total:</b> $
              {`${payment?.transactions
                .reduce(
                  (total, invoice) => total + Number(invoice.amount_paid),
                  0
                )
                .toFixed(2)}`}
            </Typography>
            <Typography
              fontSize="16px"
              fontWeight={400}
              mb="0px"
              mt="2px"
              align="right"
              mr="12px"
            >
              <b>Fees:</b> $
              {`${payment?.transactions
                .reduce((total, invoice) => total + Number(invoice.fee), 0)
                .toFixed(2)}`}
            </Typography>

            <Reconciliations payment={payment} />
            <Separator />
            <Typography fontSize={24} marginBottom={2}>
              Summary
            </Typography>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Stack>
                <Typography>Attachments</Typography>
                {payment.attachments.length == 0 ? (
                  <Typography>No attachments</Typography>
                ) : (
                  <>
                    {payment.attachments.map((att) => (
                      <Stack direction={"row"}>
                        <Typography
                          onClick={() =>
                            downloadFile(att.file, getFileName(att.file))
                          }
                          sx={{ color: "blue", cursor: "pointer" }}
                        >
                          {getFileName(att.file)}
                          {att.memo && (
                            <span style={{ color: "black" }}>({att.memo})</span>
                          )}
                        </Typography>
                        <Button
                          sx={{ ...smallButton, ml: 2 }}
                          variant={"contained"}
                          color={"error"}
                          onClick={() =>
                            deletePaymentAttachment(id, att.id).then((res) =>
                              fetchData()
                            )
                          }
                        >
                          Delete
                        </Button>
                      </Stack>
                    ))}
                  </>
                )}
                <Button
                  sx={{ ...smallButton, maxWidth: 200, mt: 1 }}
                  variant={"outlined"}
                  onClick={() => setOpenAttach(true)}
                >
                  Attach file
                </Button>
              </Stack>
              <Stack>
                <Typography
                  fontSize="16px"
                  fontWeight={400}
                  mb="0px"
                  mt="12px"
                  align="right"
                  mr="12px"
                >
                  <b>Total Allocated:</b> $
                  {`${payment.payments
                    .reduce(
                      (total, invoice) => total + Number(invoice.amount),
                      0
                    )
                    .toFixed(2)}`}
                </Typography>
                <Typography
                  fontSize="16px"
                  fontWeight={400}
                  mb="0px"
                  mt="2px"
                  align="right"
                  mr="12px"
                >
                  <b>Total Fees:</b> $
                  {`${payment.transactions
                    .reduce((total, invoice) => total + Number(invoice.fee), 0)
                    .toFixed(2)}`}
                </Typography>
                <Typography
                  fontSize="16px"
                  fontWeight={400}
                  mb="0px"
                  align="right"
                  mr="12px"
                >
                  <b>Reconciliation:</b> $
                  {`${payment.reconciliations
                    .reduce((total, rec) => total + Number(rec.amount), 0)
                    .toFixed(2)}`}
                </Typography>
                <Typography
                  fontSize="16px"
                  fontWeight={400}
                  mb="0px"
                  mt="12px"
                  align="right"
                  mr="12px"
                >
                  <b>Total Paid:</b> $
                  {`${payment.transactions
                    .reduce(
                      (total, invoice) => total + Number(invoice.amount_paid),
                      0
                    )
                    .toFixed(2)}`}
                </Typography>

                <Typography
                  fontSize="16px"
                  fontWeight={400}
                  mb="0px"
                  mt="2px"
                  align="right"
                  mr="12px"
                >
                  <b>Balance:</b> ${`${payment.balance}`}
                </Typography>
              </Stack>
            </Stack>
          </Block>
          <PaymentNotes payment={payment} id={id} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentDetail;

function AttachFile({ open, setOpen, paymentId, fetchData }) {
  const [memo, setMemo] = useState("");
  const [attachedFile, setAttachedFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleSubmit() {
    const payload = new FormData();
    payload.append("payment_group", paymentId);
    payload.append("memo", memo);
    payload.append("file", attachedFile);
    setIsSubmitting(true);
    attachPaymentFile(paymentId, payload)
      .then((res) => {
        setOpen(false);
      })
      .finally(() => {
        setIsSubmitting(false);
        fetchData();
      });
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Attach file</DialogTitle>
      <DialogContent>
        <Stack>
          <Input
            type={"file"}
            sx={{ mb: 1 }}
            onChange={(e) => {
              if (e.target.files) {
                setAttachedFile(e.target.files[0]);
              }
            }}
          />
          <TextField
            label={"Memo"}
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setOpen(false)}
          color={"error"}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleSubmit();
          }}
          disabled={isSubmitting}
        >
          Attach
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function RefundCc({ ccToRefund, setCcToRefund, fetchData }) {
  const [memo, setMemo] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [amount, setAmount] = useState(0);
  function handleSubmit() {
    console.log("cc to refund: ", ccToRefund);
    const payload = new FormData();
    payload.append("transaction_id", ccToRefund.paymentId);
    payload.append("memo", memo);
    setIsSubmitting(true);
    issueRefund({
      transaction_id: ccToRefund.id,
      memo: memo,
      amount: amount,
    })
      .then((res) => {
        setCcToRefund(null);
      })
      .finally(() => {
        setIsSubmitting(false);
        fetchData();
      });
  }
  if (!ccToRefund) {
    return <></>;
  }
  return (
    <Dialog
      open={ccToRefund}
      onClose={() => setCcToRefund(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Attach file</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Alert severity="error" sx={{ margin: 1 }}>
            Careful, you're about to issue a refund against {ccToRefund.method}{" "}
            #{ccToRefund.reference || ccToRefund.cc_digits} which has a total of
            ${ccToRefund.amount_paid}
          </Alert>
          <OutlinedInput
            fullWidth
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            name="amountPaid"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
          <Typography>Refund reason</Typography>
          <TextField value={memo} onChange={(e) => setMemo(e.target.value)} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setCcToRefund(false)}
          color={"error"}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleSubmit();
          }}
          disabled={isSubmitting}
          color="success"
        >
          Issue Refund
        </Button>
      </DialogActions>
    </Dialog>
  );
}
function VoidPayment({ paymentToVoid, setPaymentToVoid, fetchData }) {
  const [memo, setMemo] = useState("");
  const [voidFee, setVoidFee] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleSubmit() {
    const payload = new FormData();
    payload.append("payment_group", paymentToVoid.paymentId);
    payload.append("memo", memo);
    setIsSubmitting(true);
    voidPayment({
      transaction: paymentToVoid.id,
      void_reason: memo,
      void_fee: voidFee,
    })
      .then((res) => {
        setPaymentToVoid(null);
      })
      .finally(() => {
        setIsSubmitting(false);
        fetchData();
      });
  }
  if (!paymentToVoid) {
    return <></>;
  }
  return (
    <Dialog
      open={paymentToVoid}
      onClose={() => setPaymentToVoid(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Attach file</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Alert severity="error" sx={{ margin: 1 }}>
            Careful, you're about to void {paymentToVoid.method} #
            {paymentToVoid.reference || paymentToVoid.cc_digits} which has a
            total of ${paymentToVoid.amount_paid}
          </Alert>
          <OutlinedInput
            fullWidth
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            name="amountPaid"
            type="number"
            value={voidFee}
            onChange={(e) => setVoidFee(e.target.value)}
            required
          />
          <Typography>Void reason</Typography>
          <TextField value={memo} onChange={(e) => setMemo(e.target.value)} />
          <Alert severity="warning" sx={{ margin: 1 }}>
            A new invoice will be created with a total of $
            {Number(paymentToVoid.amount_paid) + Number(voidFee)}
          </Alert>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => setPaymentToVoid(false)}
          color={"error"}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleSubmit();
          }}
          disabled={isSubmitting}
          color="success"
        >
          Void payment
        </Button>
      </DialogActions>
    </Dialog>
  );
}
