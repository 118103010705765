import { Alert, Drawer, List, Toolbar, Typography } from "@mui/material";
import SidebarLink from "./SidebarLink";
import SidebarNestedLink from "./SidebarNestedLink";
import LinkButton from "./LinkButton";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import Person4OutlinedIcon from "@mui/icons-material/Person4Outlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import RouteOutlinedIcon from "@mui/icons-material/RouteOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MovingOutlinedIcon from "@mui/icons-material/MovingOutlined";
import RequestQuoteSharpIcon from "@mui/icons-material/RequestQuoteSharp";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import DevicesTwoToneIcon from "@mui/icons-material/DevicesTwoTone";
import NotificationsNoneTwoToneIcon from "@mui/icons-material/NotificationsNoneTwoTone";
import {
  AlignVerticalTopOutlined,
  Analytics,
  Warning,
} from "@mui/icons-material";
import { getApiUrl } from "../../distflowAPI/api";

const DrawerContent = ({ onClose }) => (
  <div>
    <Toolbar sx={{ marginBottom: "20px" }} />
    {getApiUrl() == "http://50.16.152.7:8000" && (
      <Alert severity={"error"}>Test Env</Alert>
    )}
    {getApiUrl() == "http://127.0.0.1:8000" && (
      <Alert severity={"error"}>Test Env LOCAL</Alert>
    )}
    <Typography variant="sidebarLabel">Administration</Typography>
    <List>
      <SidebarLink
        to="/managers"
        title="Managers"
        onClick={onClose}
        testid={"users-page-link"}
      >
        <ManageAccountsOutlinedIcon />
      </SidebarLink>
      <SidebarLink
        to="/vendors"
        title="Vendors"
        onClick={onClose}
        testid={"vendors-page-link"}
      >
        <StorefrontOutlinedIcon />
      </SidebarLink>
      <SidebarNestedLink
        title="Customers"
        icon={<Person4OutlinedIcon />}
        testid={"customers-open-button"}
      >
        <LinkButton
          to="/customers"
          title="Customers"
          onClick={onClose}
          testid={"customers-page-link"}
        />
        <LinkButton
          to="/territories"
          title="Territories"
          onClick={onClose}
          testid={"territories-page-link"}
        />
        <LinkButton
          to="/customers/emailsend"
          title="Email Correspondence"
          onClick={onClose}
          testid={"territories-page-link"}
        />
        <LinkButton
          to="/customers/visible_items"
          title="Products Visibility"
          onClick={onClose}
          testid={"territories-page-link"}
        />
        <LinkButton
          to="/customers/daily_reminders"
          title="Daily Reminders"
          onClick={onClose}
          testid={"territories-page-link"}
        />
      </SidebarNestedLink>

      <SidebarNestedLink
        title="Accounts"
        icon={<ApartmentOutlinedIcon />}
        testid={"accounts-open-button"}
      >
        <LinkButton
          to="/accounts"
          title="Accounts"
          onClick={onClose}
          testid={"accounts-page-link"}
        />
        <LinkButton
          to="/groups"
          title="Accounts Types"
          onClick={onClose}
          testid={"groups-page-link"}
        />
      </SidebarNestedLink>

      <SidebarNestedLink
        title="Products"
        icon={<DiscountOutlinedIcon />}
        testid={"products-open-button"}
      >
        <LinkButton
          to="/products"
          title="Products"
          onClick={onClose}
          testid={"products-page-link"}
        />
        <LinkButton
          to="/variants"
          title="Variants"
          onClick={onClose}
          testid={"variants-page-link"}
        />
        <LinkButton
          to="/tags"
          title="Tags"
          onClick={onClose}
          testid={"tags-page-link"}
        />
        <LinkButton
          to="/categories"
          title="Categories"
          onClick={onClose}
          testid={"categories-page-link"}
        />
      </SidebarNestedLink>
      <SidebarNestedLink
        title="Routes"
        icon={<RouteOutlinedIcon />}
        testid={"routes-open-button"}
      >
        <LinkButton
          to="/routes2/routes/"
          title="Routes"
          onClick={onClose}
          testid={"routes-page-link"}
        />
        <LinkButton
          to="/routes2/routes-changes/"
          title="Changes"
          onClick={onClose}
          testid={"routes-changes-page-link"}
        />
        <LinkButton
          to="/routes2/drivers/"
          title="Drivers"
          onClick={onClose}
          testid={"drivers-page-link"}
        />
        <LinkButton
          to="/routes2/routes_reports/"
          title="Reports"
          onClick={onClose}
        />
      </SidebarNestedLink>
      <SidebarNestedLink
        title="Overrides"
        icon={<MovingOutlinedIcon />}
        testid={"override-open-button"}
      >
        <LinkButton
          to="/vendor-category-overrides"
          title="Vendor Category"
          onClick={onClose}
          testid={"vendor-category-page-link"}
        />
      </SidebarNestedLink>
    </List>
    <Typography variant="sidebarLabel" mt={2}>
      Management
    </Typography>
    <List>
      <SidebarLink
        to="/orders"
        title="Orders"
        onClick={onClose}
        testid={"orders-page-link"}
      >
        <ListAltOutlinedIcon />
      </SidebarLink>
    </List>
    <Typography variant="sidebarLabel" mt={2}>
      Accounting
    </Typography>
    <List>
      <SidebarNestedLink
        title="Invoices"
        icon={<ShoppingCartOutlinedIcon />}
        testid={"invoices-open-button"}
      >
        <LinkButton
          to="/invoices/invoices?status=PARTIALLY_PAID%2CUNPAID"
          title="Invoices"
          onClick={onClose}
          testid={"invoices-page-link"}
        />
        <LinkButton
          to="/invoices/payments"
          title="Payments"
          onClick={onClose}
          testid={"payments-page-link"}
        />
        <LinkButton
          to="/invoices/collections"
          title="Collection"
          onClick={onClose}
          testid={"collection-page-link"}
        />
        <LinkButton
          to="/invoices/transactions"
          title="Transactions"
          onClick={onClose}
          testid={"transactions-page-link"}
        />
        <LinkButton
          to="/invoices/statement_reports"
          title="Statement Reports"
          onClick={onClose}
          testid={"statements-page-link"}
        />
        <LinkButton
          to="/invoices/vendor_report_comparator"
          title="Vendor Statement Comparator"
          onClick={onClose}
          testid={"statements-page-link"}
        />
        <LinkButton
          to="/invoices/bank_transactions"
          title="Check Transactions"
          onClick={onClose}
          testid={"statements-page-link"}
        />
        <LinkButton
          to="/invoices/cc_transactions"
          title="CC Transactions"
          onClick={onClose}
          testid={"statements-page-link"}
        />
        <LinkButton
          to="/invoices/group-statements/"
          title="Group Statements"
          onClick={onClose}
          testid={"statements-page-link"}
        />
        <LinkButton
          to="/invoices/weekly-invoices/"
          title="Weekly Invoices"
          onClick={onClose}
          testid={"statements-page-link"}
        />
      </SidebarNestedLink>
      <SidebarLink
        to="/salesmen"
        title="Salesmen"
        onClick={onClose}
        testid={"users-page-link"}
      >
        <RequestQuoteSharpIcon />
      </SidebarLink>
      <SidebarLink
        to="/pnl"
        title="P&L"
        onClick={onClose}
        testid={"pnl-page-link"}
      >
        <Analytics />
      </SidebarLink>
    </List>
    <Typography variant="sidebarLabel" mt={2}>
      Advanced
    </Typography>
    <List>
      <SidebarLink
        to="/settings"
        title="Settings"
        onClick={onClose}
        testid={"settings-page-link"}
      >
        <SettingsOutlinedIcon />
      </SidebarLink>
      <SidebarLink
        to="/email-queue"
        title="Email Queue"
        onClick={onClose}
        testid={"email-queue-page-link"}
      >
        <MarkEmailReadOutlinedIcon />
      </SidebarLink>
      <SidebarLink
        to="/whitelisted-ips"
        title="Whitelisted IPs"
        onClick={onClose}
        testid={"whitelisted-ips-page-link"}
      >
        <DevicesTwoToneIcon />
      </SidebarLink>
      <SidebarLink
        to="/notifications"
        title="Notifications"
        onClick={onClose}
        testid={"notifications-page-link"}
      >
        <NotificationsNoneTwoToneIcon />
      </SidebarLink>
      <SidebarLink
        to="/cp_alerts"
        title="ALERTS"
        onClick={onClose}
        testid={"notifications-page-link"}
      >
        <Warning />
      </SidebarLink>
    </List>
  </div>
);

const Sidebar = ({ drawerWidth, mobileOpen, handleDrawerToggle }) => {
  return (
    <>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: "#F6F6F7",
          },
        }}
      >
        <DrawerContent onClose={handleDrawerToggle} />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: "#F6F6F7",
          },
        }}
        open
      >
        <DrawerContent />
      </Drawer>
    </>
  );
};

export default Sidebar;
