import {
  DialogTitle,
  DialogContent,
  Box,
  Pagination,
  Typography,
  Stack,
} from "@mui/material";
import { getNoteAccount } from "../distflowAPI/accountsApi";
import { useState, useEffect } from "react";
import { parseISO, format } from "date-fns";

export default function NotesModal({ open, setOpen, account, isInvoice }) {
  const [notes, setNotes] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [count, setCount] = useState(0);
  function fetchNotes() {
    if (!account) {
      setNotes([]);
      setCount(0);
      return;
    }
    if (account?.id || account?.value) {
      getNoteAccount(isInvoice ? account?.id || account?.value : account?.value)
        .then((res) => {
          setNotes(res.data.results);
          setCount(res.data.count);
        })
        .catch((err) => console.log(err));
    }
  }

  useEffect(() => {
    fetchNotes();
    console.log(account);
  }, [account, isInvoice]);
  if (!(account?.id || account?.value)) {
    return <Typography></Typography>;
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",

        gap: "1rem",
        position: "relative",
      }}
    >
      <Typography sx={{ textAlign: "center" }}>
        Notes for {isInvoice ? account?.name : account?.label}
      </Typography>
      {(account?.id || account?.value) && notes.length == 0 && (
        <Typography>No notes</Typography>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          gap: "1rem",
          padding: "1rem",
        }}
      >
        {notes.map((note) => (
          <Box key={note.id}>
            <Stack>
              <Typography>- {note.note}</Typography>
              <Typography>
                <small>{format(parseISO(note.created_at), "MM/dd/yyyy")}</small>
              </Typography>
            </Stack>
          </Box>
        ))}

        <Pagination
          sx={{
            alignSelf: "center",
          }}
          count={Math.ceil(count / pageSize)}
          page={page}
          onChange={(e, value) => setPage(value)}
        />
      </Box>
    </Box>
  );
}
