import { useState, useRef, useEffect } from "react";
import {
  Button,
  Typography,
  Stack,
  TextField,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Input,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Block from "../../components/Block";
import SearchIcon from "@mui/icons-material/Search";
import DataTable from "../../components/DataTable";
import { Link } from "react-router-dom";
import {
  createCpAlert,
  deleteCpAlert,
  listCpAlerts,
  patchCpAlert,
} from "../../distflowAPI/settingsApi";
import { DATE_FORMAT, DATE_FORMAT_FRONT } from "../../utils/constants";
import dayjs from "dayjs";
import DatePickerCustom2 from "../../components/DatePicker2";
import { smallButton } from "../../utils/smallButton";
import { CheckBox } from "@mui/icons-material";
import RouteSelect from "../../components/AsyncSelects/RouteSelect";

const colorChoices = {
  WARNING: "orange",
  ERROR: "red",
  SUCCESS: "green",
  INFO: "blue",
};
const levelChoices = {
  PUBLIC: "C. Portal",
  DRIVERS: "Drivers",
};
const defaultColDef = {
  flex: 1,
  filter: false,
  floatingFilter: false,
};

function getDateOrEmpty(date) {
  const val = dayjs(date);
  return val.isValid() ? val.format(DATE_FORMAT_FRONT) : "-";
}

const CustomerPortalAlert = () => {
  const columnDefs = [
    {
      headerName: "Message",
      field: "message",
      filter: false,
      flex: 7,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params) => (
        <Typography
          sx={{
            lineBreak: "auto",
            wordBreak: "break-word",
            whiteSpace: "normal",
            overflow: "visible",
          }}
        >
          {params.data.message}
        </Typography>
      ),
    },
    {
      headerName: "Level",
      field: "level",
      valueGetter: (params) => params.data.level.replace("PUBLIC", "C. portal"),
    },
    {
      headerName: "Start Date",
      field: "start_date",
      valueGetter: (params) => getDateOrEmpty(params.data.start_date),
    },
    {
      headerName: "End Date",
      field: "end_date",
      valueGetter: (params) => getDateOrEmpty(params.data.end_date),
    },
    {
      headerName: "Alert Color",
      field: "severity",
      valueGetter: (params) => colorChoices[params.data.severity],
    },
    {
      headerName: "Actions",
      cellRenderer: (params) => (
        <Stack direction={"row"} spacing={2}>
          <Button
            variant="contained"
            sx={smallButton}
            onClick={() => {
              setIsCreateOpen(false);
              setMessageToEdit(params.data);
            }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={smallButton}
            onClick={() =>
              deleteCpAlert(params.data.id).then((res) => updateTable())
            }
          >
            Delete
          </Button>
        </Stack>
      ),
      flex: 1.5,
    },
  ];
  const [rawTableData, setrawTableData] = useState([]);
  const gridRef = useRef();
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [messageToEdit, setMessageToEdit] = useState(false);
  function updateTable() {
    listCpAlerts().then((res) => {
      setrawTableData(res.data.results);
    });
  }
  useEffect(() => {
    updateTable();
  }, []);
  return (
    <>
      <Typography
        fontSize="23px"
        fontWeight={800}
        component="h2"
        data-testid="page-title"
      >
        Alerts
      </Typography>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "space-between", sm: "center" }}
        justifyContent="space-between"
        spacing={1.5}
        my="25px"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing="15px"
        >
          <Button
            className="btn-height"
            to="/settings/create"
            variant="contained"
            onClick={() => setIsCreateOpen(true)}
          >
            Add New
          </Button>
        </Stack>
      </Stack>

      <Box className="table-wrapper">
        <DataTable
          rowData={rawTableData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowHeight={53}
          headerHeight={60}
          ref={gridRef}
        />
      </Box>
      <CreateAlert
        open={isCreateOpen}
        setOpen={setIsCreateOpen}
        onConfirm={(payload) => {
          createCpAlert(payload).then(
            (res) => {
              console.log("res is: ", res);
              updateTable();
              setIsCreateOpen(false);
            },
            (err) => {
              console.log("error: ", err);
            }
          );
        }}
      />
      <UpdateAlert
        messageToEdit={messageToEdit}
        setMessageToEdit={setMessageToEdit}
        onConfirm={(payload) => {
          patchCpAlert(messageToEdit.id, payload).then(
            (res) => {
              console.log("res is: ", res);
              updateTable();
              setIsCreateOpen(false);
              setMessageToEdit(false);
            },
            (err) => {
              console.log("error: ", err);
            }
          );
        }}
      />
    </>
  );
};

export default CustomerPortalAlert;

function AlertCreateEdit({ open, setOpen, messageToEdit, onConfirm }) {
  const [message, setMessage] = useState(messageToEdit?.message || "");
  const [startDate, setStartDate] = useState(messageToEdit?.start_date || null);
  const [endDate, setEndDate] = useState(messageToEdit?.end_date || null);
  const [severity, setSeverity] = useState(
    messageToEdit?.severity || "WARNING"
  );
  const [level, setLevel] = useState(messageToEdit?.level || "PUBLIC");
  const [allRoutes, setAllRoutes] = useState(false);
  const [selectedRoutes, setSelectedRoutes] = useState([]);

  console.log("message to edit: ", messageToEdit);
  useEffect(() => {
    if (messageToEdit) {
      setMessage(messageToEdit?.message);
      setStartDate(messageToEdit?.start_date);
      setEndDate(messageToEdit.end_date);
      setSeverity(messageToEdit?.severity);
      setLevel(messageToEdit?.level);
      setAllRoutes(messageToEdit?.all_routes);
      setSelectedRoutes(messageToEdit?.routes);
    }
  }, [messageToEdit]);

  function handleSave() {
    if (!message) {
      alert("Message can not be empty");
    }
    if (!allRoutes && selectedRoutes.length == 0) {
      alert("select at least one route");
    }
    onConfirm({
      message,
      start_date: startDate
        ? startDate.isValid()
          ? startDate.format(DATE_FORMAT)
          : null
        : null,
      end_date: endDate
        ? endDate.isValid()
          ? endDate.format(DATE_FORMAT)
          : null
        : null,
      severity,
      level,
      all_routes: allRoutes,
      routes: selectedRoutes.map((r) => r.value),
    });
  }
  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography>Create a new alert</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography>Message</Typography>
          <Input
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
          />
          <Stack direction={"row"} spacing={2}>
            <Stack>
              <Typography>Start Date</Typography>
              <DatePickerCustom2
                label={"Start Date"}
                controlledValue={startDate}
                setControlledValue={setStartDate}
              />
            </Stack>
            <Stack>
              <Typography>End Date</Typography>
              <DatePickerCustom2
                label={"End Date"}
                controlledValue={endDate}
                setControlledValue={setEndDate}
              />
            </Stack>
          </Stack>
          <Stack direction={"row"} spacing={2}>
            <Stack>
              <Typography>Alert Color</Typography>
              <Select
                value={severity}
                label="Color"
                onChange={(e) => {
                  setSeverity(e.target.value);
                }}
                sx={{ minWidth: 270, zIndex: 1800, maxHeight: 40 }}
                MenuProps={{
                  style: { zIndex: 35001 },
                }}
              >
                {Object.keys(colorChoices).map((colorKey) => {
                  return (
                    <MenuItem value={colorKey}>
                      {colorChoices[colorKey]}
                    </MenuItem>
                  );
                })}
              </Select>
            </Stack>
            <Stack>
              <Typography>Alert Portal</Typography>
              <Select
                value={level}
                label="Alert Portal"
                onChange={(e) => {
                  setLevel(e.target.value);
                }}
                sx={{ minWidth: 270, zIndex: 1800, maxHeight: 40 }}
                MenuProps={{
                  style: { zIndex: 35001 },
                }}
              >
                {Object.keys(levelChoices).map((levelKey) => {
                  return (
                    <MenuItem value={levelKey}>
                      {levelChoices[levelKey]}
                    </MenuItem>
                  );
                })}
              </Select>
            </Stack>
          </Stack>
          {level == "DRIVERS" && (
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Stack>
                <Typography>&nbsp;</Typography>
                <Stack direction={"row"} alignItems={"center"} minWidth={270}>
                  <Checkbox
                    checked={allRoutes}
                    onChange={(e) => setAllRoutes(e.target.checked)}
                  />
                  <Typography>Apply to all routes?</Typography>
                </Stack>
              </Stack>
              {!allRoutes && (
                <Stack minWidth={270}>
                  <Typography>Routes</Typography>
                  <RouteSelect
                    fullWidth
                    multiple={true}
                    value={selectedRoutes}
                    onChange={(e, v) => {
                      console.log("new route: ", v);
                      setSelectedRoutes(v);
                    }}
                  />
                </Stack>
              )}
            </Stack>
          )}
          {message && (
            <>
              <Typography>Preview</Typography>
              <Alert severity={severity.toLowerCase()}>{message}</Alert>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          color={"error"}
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button variant={"contained"} onClick={() => handleSave()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function CreateAlert({ open, setOpen, onConfirm }) {
  return (
    <AlertCreateEdit open={open} setOpen={setOpen} onConfirm={onConfirm} />
  );
}
function UpdateAlert({ messageToEdit, setMessageToEdit, onConfirm }) {
  return (
    <AlertCreateEdit
      open={messageToEdit !== false}
      setOpen={setMessageToEdit}
      onConfirm={onConfirm}
      messageToEdit={messageToEdit}
    />
  );
}
