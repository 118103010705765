import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import DatePickerCustom2 from "../../../components/DatePicker2";
import dayjs from "dayjs";
import { useState } from "react";
import { generateSteadiesExport } from "../../../distflowAPI/ordersApi";
import { DATE_FORMAT } from "../../../utils/constants";
import { downloadFile } from "../../../distflowAPI/downloadFile";
function GenerateSteadiesEmail({ open, setOpen }) {
  const [downloadingSteadies, setDownloadingSteadies] = useState(false);
  const [emailing, setEmailing] = useState(false);
  function downloadSteadies() {
    setDownloadingSteadies(true);
    downloadFile(
      `/api/orders/export_future_orders/?type=config`,
      "steadies.csv"
    ).finally((res) => {
      setDownloadingSteadies(false);
      setOpen(false);
    });
  }
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Typography>Email Steadies</Typography>
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}></DialogContent>
      <DialogActions>
        <Stack direction={"row"} spacing={2}>
          <Button
            disabled={downloadingSteadies}
            variant="contained"
            color="error"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
          <Button
            disabled={downloadingSteadies}
            onClick={downloadSteadies}
            variant="contained"
            color="success"
          >
            Download Config
          </Button>
          <Button
            disabled={downloadingSteadies}
            onClick={() => {
              setEmailing(true);
              generateSteadiesExport().finally(() => setEmailing(false));
            }}
            variant="contained"
            color="success"
          >
            Email Future Orders
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default GenerateSteadiesEmail;
