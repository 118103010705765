import React, { useEffect, useState, useReducer } from "react";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Input,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Block from "../../components/Block";
import useAppDispatch from "../../hooks/useAppDispatch";
import { deletePayment, updatePayment } from "../../redux/module/payment";
import AccountSelect from "../../components/AsyncSelects/AccountSelect";
import ConfirmDialog from "../../components/ConfirmDialog";
import UpdateItemTitle from "../../components/UpdateItemTitle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { fixedNumber } from "../../utils";
import DatePickerCustom2 from "../../components/DatePicker2";
import dayjs from "dayjs";
import { createPayment, getPayment } from "../../distflowAPI/paymentsApi";
import Separator from "../../components/Separator";
import CustomerSelect from "../../components/AsyncSelects/CustomerSelect";
import InvoicesSelect from "./invoiceSelect";
import RedeemCredit from "./redeemCredit";
import PaymentSummary from "./paymentSummary";
import SelectedInvoices from "./selectedInvoices";
import {
  SUPPORTED_METHODS,
  REDUCER_ACTIONS,
  INIT_PAYMENT,
  reducer,
} from "./createPaymentComponents/config";
import PaymentGeneralInfo from "./createPaymentComponents/PaymentGeneralInfo";
import {
  getBalance,
  getTotalAllocated,
  getTotalCredit,
} from "./createPaymentComponents/config";
import { listInvoices } from "../../distflowAPI/invoicesApi";
import { getCustomerById } from "../../distflowAPI/customersApi";
import { smallButton } from "../../utils/smallButton";

const CreatePaymentPage = () => {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const [paymentsLoading, setPaymentsLoading] = useState(false);
  const [payment, setPayment] = useState({});
  const [isCreatePayment] = useState(id === "new");
  const [backButtonLink, setBackButtonLink] = useState("");
  const [isOpenDiscard, setIsOpenDiscard] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [data, dataDispatch] = useReducer(reducer, INIT_PAYMENT);
  const [errors, setErrors] = useState({});

  function initAccount() {
    if (searchParams.get("account_id") && searchParams.get("account_name")) {
      dataDispatch({
        type: REDUCER_ACTIONS.SET_VAL,
        key: "account",
        value: {
          label: searchParams.get("account_name"),
          value: searchParams.get("account_id"),
        },
      });
      dataDispatch({
        type: REDUCER_ACTIONS.SET_VAL,
        key: "payment_level",
        value: "account",
      });
    }
  }
  function initCustomer() {
    if (searchParams.get("customer_id")) {
      getCustomerById(searchParams.get("customer_id")).then((res) => {
        dataDispatch({
          type: REDUCER_ACTIONS.SET_VAL,
          key: "customer",
          value: {
            label: res.data.name,
            value: res.data.id,
          },
        });
        dataDispatch({
          type: REDUCER_ACTIONS.SET_VAL,
          key: "payment_level",
          value: "customer",
        });
      });
    }
  }
  function initInvoice() {
    if (searchParams.get("invoice_id")) {
      listInvoices(
        1,
        50,
        [
          {
            column: "id",
            value: searchParams.get("invoice_id"),
          },
        ],
        []
      ).then((res) => {
        if (res.data && res.data.results && res.data.results.length > 0) {
          includeInvoice(res.data.results[0]);
        }
      });
    }
  }
  useEffect(() => {
    initAccount();
    initCustomer();
    initInvoice();
  }, [searchParams]);

  function excludeInvoice(id) {
    dataDispatch({
      type: REDUCER_ACTIONS.EXCLUDE_INVOICE,
      id,
    });
  }

  function includeInvoice(inv) {
    dataDispatch({
      type: REDUCER_ACTIONS.INCLUDE_INVOICE,
      invoice: {
        invoice: inv.id,
        amount: fixedNumber(inv.total_amount - inv.total_paid),
        remaining: fixedNumber(inv.total_amount - inv.total_paid),
        invoice_amount: inv.total_amount,
        total_paid: inv.total_paid,
        account: inv.account.name,
        date: inv.issued_date,
      },
    });
  }

  function setPaymentAmount(index, amount) {
    dataDispatch({
      type: REDUCER_ACTIONS.SET_PAID_AMOUNT,
      amount,
      index,
    });
  }

  function setValue(name, value) {
    dataDispatch({
      type: REDUCER_ACTIONS.SET_VAL,
      key: name,
      value,
    });
  }

  const handleDiscard = async () => {
    getPayment(id).then((res) => setPayment(res.data));
  };

  const handleDelete = async () => {
    //await dispatch(deletePayment(id));
    navigate(-1);
  };

  const handleSubmit = async (event) => {
    {
      if (isSaving) {
        return;
      }
      setIsSaving(true);
      event.preventDefault();
      const payload = {
        account: data.account?.value,
        customer: data.customer?.value,
        notes: data.notes,
        payment_date: data.payment_date,
        payments: [],
        transactions: [data.payment_data],
        applied_credits: [],
        credits_to_create: data.credits_to_create,
      };
      setErrors({});
      payload["payment_date"] = payload["payment_date"].format("YYYY-MM-DD");
      data.included_invoices.map((invoice) =>
        payload.payments.push({
          invoice: invoice.invoice,
          amount: invoice.amount,
        })
      );
      Object.keys(data.applied_credits).map((key) =>
        payload.applied_credits.push(key)
      );

      createPayment(payload)
        .then(
          (res) => {
            if (res.data.id) {
              navigate(`/invoices/payments/${res.data.id}/details`);
            }
          },
          (err) => {
            if (err.response.data && `${err.response.data}`?.length < 200) {
              setErrors(err.response.data);
            } else {
              setErrors({ error: "unexpected error" });
            }
          }
        )
        .finally(() => {
          setIsSaving(false);
        });
      /*


    
    
    
    Object.keys(transactions).map((tk) =>
      payload.transactions.push(transactions[tk]),
    );
    Object.keys(appliedCredits).map((ac) => {
      payload.transactions.push({
        ...appliedCredits[ac],
        tid: appliedCredits[ac].id,
      });
    });
    if (isCreatePayment) {
       ;
      
    } else {
      await updatePayment({ id: payment.id, payload });
    }

     ;
*/
    }
  };

  function removeItem(obj, key) {
    const newObj = {};
    Object.keys(obj).map((k) => {
      if (k != key) {
        newObj[k] = obj[k];
      }
    });
    return newObj;
  }

  const [openRedeemCredit, setOpenRedeemCredit] = useState(false);

  const [paymentLevel, setPaymentLevel] = useState("account");
  useEffect(() => {
    if (paymentLevel == "account") {
      //setData({ ...data, customer: { label: "", value: null } });
    } else {
      //setData({ ...data, account: { label: "", value: null } });
    }
  }, [paymentLevel]);

  return (
    <Box maxWidth="1600px" margin=" 30px auto 0">
      <ConfirmDialog
        title="Discard updates?"
        open={isOpenDiscard}
        setOpen={setIsOpenDiscard}
        onConfirm={handleDiscard}
      >
        {`Are you sure you want to discard all changes?`}
      </ConfirmDialog>
      <ConfirmDialog
        title="Delete payment?"
        open={isOpenDelete}
        setOpen={setIsOpenDelete}
        onConfirm={handleDelete}
      >
        {`Are you sure you want to delete this payment?`}
      </ConfirmDialog>
      <form id="PaymentUpdate" onSubmit={handleSubmit}>
        <PaymentPageTitle
          isCreatePayment={isCreatePayment}
          setIsOpenDelete={setIsOpenDelete}
          setIsOpenDiscard={setIsOpenDiscard}
          backButtonLink={backButtonLink}
          paymentsLoading={paymentsLoading}
          navigate={navigate}
        />
        <Grid container columnSpacing={{ xs: 1, sm: "20px" }} margin="36px 0 0">
          <Grid item width="100%" xl={12} marginBottom={2}>
            <Block isLoading={paymentsLoading}>
              {Object.keys(errors).map((error) => (
                <Alert severity="error">
                  {`${error}`}:{`${errors[error]}`}
                </Alert>
              ))}

              <PaymentGeneralInfo data={data} dispatch={dataDispatch} />
              <Box>
                <Separator />
              </Box>
              <PaymentDetails data={data} dispatch={dataDispatch} />
              <Box>
                <Separator />
              </Box>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <SelectedInvoices
                    data={data}
                    payments={data.included_invoices}
                    excludeInvoice={excludeInvoice}
                    setIsOpenSelectInvoices={(val) =>
                      dataDispatch({
                        type: REDUCER_ACTIONS.SET_VAL,
                        key: "open_select_invoices",
                        value: val,
                      })
                    }
                    setPaymentAmount={setPaymentAmount}
                  />
                </Grid>

                <PaymentSummary data={data} />

                <Grid item md={12}>
                  <Separator />
                  <Typography
                    fontSize={20}
                    fontWeight={600}
                    marginBottom="10px"
                  >
                    Memo
                  </Typography>
                  <TextField
                    rows={3}
                    fullWidth
                    multiline
                    value={data.notes}
                    onChange={(e) => setValue("notes", e.target.value)}
                  />
                </Grid>
                <Grid item md={12}>
                  <Separator />
                </Grid>
                {Object.keys(errors).map((err) => (
                  <Grid item md={12}>
                    <Alert severity="error">
                      {`${err}`}: {`${errors[err]}`}
                    </Alert>
                  </Grid>
                ))}
                <Grid item md={12}>
                  <Button
                    size="small"
                    color="black"
                    variant="contained"
                    type="submit"
                    disabled={paymentsLoading || isSaving}
                  >
                    Save payment
                  </Button>
                </Grid>
              </Grid>
            </Block>
            <RedeemCredit
              open={data.open_redeem_credit}
              setOpen={(val) =>
                dataDispatch({
                  type: REDUCER_ACTIONS.SET_VAL,
                  key: "open_redeem_credit",
                  value: val,
                })
              }
              accountId={data.account.value}
              customerId={data.customer.value}
              existingCredits={data.applied_credits}
              addToCredits={(credit) => {
                dataDispatch({
                  type: REDUCER_ACTIONS.INCLUDE_CREDIT,
                  credit: credit,
                });
              }}
            />
            <InvoicesSelect
              open={data.open_select_invoices}
              setOpen={(val) =>
                dataDispatch({
                  type: REDUCER_ACTIONS.SET_VAL,
                  key: "open_select_invoices",
                  value: val,
                })
              }
              accountId={data.account?.value}
              customerId={data.customer?.value}
              includeInvoice={includeInvoice}
              excludeInvoice={excludeInvoice}
              payments={data.included_invoices}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default CreatePaymentPage;

function CreditInput({ val, delItem, updateItem }) {
  return (
    <TableRow>
      <TableCell>
        <Input fullWidth value={"INLINE CUSTOMER CREDIT"} disabled={true} />
      </TableCell>
      <TableCell>
        <Input
          fullWidth
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          name="amountPaid"
          type="number"
          value={val?.amount_paid}
          onChange={(e) => updateItem("amount_paid", e.target.value)}
          required
        />
      </TableCell>
      <TableCell>
        <Input
          fullWidth
          name="memo"
          type="string"
          value={val?.notes}
          onChange={(e) => updateItem("notes", e.target.value)}
        />
      </TableCell>
      <TableCell>
        <Button
          onClick={() => delItem()}
          sx={smallButton}
          variant="contained"
          color="error"
        >
          X
        </Button>
      </TableCell>
    </TableRow>
  );
}

function CreditDisplay({ val, delItem }) {
  console.log("credit is: ", val);
  function getCreditType() {
    if (val.method == "CREDIT_CARD_REFUND") {
      const ccf = val?.cc_digits ? `CC: ${val?.cc_digits}` : "";
      return "CREDIT CARD REFUND " + ccf;
    }
    return "EXISTING CUSTOMER CREDIT";
  }
  return (
    <TableRow>
      <TableCell>
        <Input fullWidth value={getCreditType()} disabled={true} />
      </TableCell>
      <TableCell>
        <Input
          fullWidth
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          name="amountPaid"
          type="number"
          value={parseFloat(val?.amount_paid) + parseFloat(val?.fee || 0) || 0}
          required
          disabled={true}
        />
      </TableCell>
      <TableCell>
        <Input
          fullWidth
          name="memo"
          type="string"
          value={val?.notes}
          disabled={true}
        />
      </TableCell>
      <TableCell>
        <Button
          onClick={() => delItem(val.id)}
          sx={smallButton}
          variant="contained"
          color="error"
        >
          X
        </Button>
      </TableCell>
    </TableRow>
  );
}

function PaymentPageTitle({
  isCreatePayment,
  setIsOpenDelete,
  setIsOpenDiscard,
  backButtonLink,
  paymentsLoading,
  navigate,
}) {
  return (
    <>
      {isCreatePayment ? (
        <Stack
          direction={{ xs: "column", md: "row" }}
          alignItems={{ xs: "flex-start", md: "center" }}
          justifyContent="space-between"
          spacing="20px"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            sx={{ width: { xs: "100%", sm: "initial" }, gap: "20px" }}
          >
            <Button
              onClick={() => navigate(backButtonLink || -1)}
              variant="outlined"
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
            <Typography
              variant="pageTitle"
              noWrap
              component="p"
              data-testid="page-title"
            >
              Create Payment
            </Typography>
          </Stack>
          <Stack
            direction={{ sm: "row-reverse", md: "row" }}
            alignItems="center"
            justifyContent="flex-end"
            flexWrap="wrap"
            sx={{
              width: {
                xs: "100%",
                sm: "initial",
              },
              gap: "11px",
              "@media(max-width: 600px)": {
                flexDirection: "row-reverse",
                width: "100%",
              },
              "@media(max-width: 480px)": {
                flexDirection: "column-reverse",
                width: "100%",
                button: { width: "100%" },
              },
            }}
          ></Stack>
        </Stack>
      ) : (
        <UpdateItemTitle
          item={"Payment"}
          setIsOpenDelete={setIsOpenDelete}
          setIsOpenDiscard={setIsOpenDiscard}
          isSubmitting={paymentsLoading}
          backLink={backButtonLink}
        />
      )}
    </>
  );
}

function PaymentDetails({ data, dispatch }) {
  function updatePaymentData(key, val) {
    dispatch({
      type: REDUCER_ACTIONS.ALTER_PAYMENT_VAL,
      value: val,
      key: key,
    });
  }

  return (
    <Stack
      direction={"row"}
      spacing={2}
      divider={<Divider orientation="vertical" flexItem />}
    >
      {/* Payment block*/}
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Stack
            direction="row"
            spacing={2}
            alignItems={"center"}
            justifyItems={"center"}
          >
            <Typography
              fontSize={20}
              fontWeight={600}
              marginBottom="10px"
              marginTop="10px"
            >
              Payment details
            </Typography>
            <Button
              variant="outlined"
              onClick={() =>
                dispatch({
                  type: REDUCER_ACTIONS.SET_VAL,
                  key: "open_redeem_credit",
                  value: true,
                })
              }
              sx={{
                display: "flex",
                flexShrink: 0,
                justifyContent: "center",
                alignItems: "center",
                padding: "4px",
                fontSize: "12px",
                minWidth: "30px",
                height: "25px !important",
              }}
            >
              Use credit/balance
            </Button>
            <Typography>OR</Typography>
            <Button
              variant={"outlined"}
              sx={{
                display: "flex",
                flexShrink: 0,
                justifyContent: "center",
                alignItems: "center",
                padding: "4px",
                fontSize: "12px",
                minWidth: "30px",
                height: "25px !important",
              }}
              onClick={(e) =>
                dispatch({
                  type: REDUCER_ACTIONS.CREATE_CREDIT,
                  data: {
                    amount_paid: 0,
                    notes: "INLINE CREDIT",
                  },
                })
              }
            >
              Create credit
            </Button>
          </Stack>
          <Grid item md={12} marginTop={1}>
            <Grid container spacing={2}>
              <Grid item md={1.5}>
                <Typography variant="inputLabel" component="p">
                  Payment Method
                </Typography>
                <Select
                  value={data.payment_data.method}
                  sx={{
                    height: "40px",
                  }}
                  onChange={(e) => updatePaymentData("method", e.target.value)}
                  fullWidth
                  required
                >
                  {SUPPORTED_METHODS.map((m) => (
                    <MenuItem value={m.value} key={m.key}>
                      {m.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              {data.payment_data.method != "NONE" && (
                <Grid item width="100%" md={1.5} marginLeft={2}>
                  <Stack>
                    <Typography variant="inputLabel" component="p">
                      Amount
                    </Typography>
                  </Stack>
                  <Input
                    fullWidth
                    sx={{
                      height: "40px",
                      "& .MuiInputBase-input": {
                        height: "40px",
                        boxSizing: "border-box",
                      },
                    }}
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    name="amountPaid"
                    type="number"
                    value={data.payment_data.amount_paid}
                    onChange={(e) => {
                      const r = /^\$?[0-9]+\.?[0-9]?[0-9]?$/;
                      if (e.target.value == "") {
                        updatePaymentData("amount_paid", "");
                      }
                      if (r.test(e.target.value)) {
                        updatePaymentData("amount_paid", e.target.value);
                      }
                    }}
                    required
                  />
                </Grid>
              )}

              {data.payment_data.method == "CREDIT_CARD" && (
                <>
                  <Grid item md={1.5}>
                    <Stack
                      direction="row"
                      alignItems={"center"}
                      justifyItems={"center"}
                      spacing={1}
                    >
                      <Typography variant="inputLabel" component="p">
                        CC fee
                      </Typography>
                    </Stack>
                    <Input
                      fullWidth
                      sx={{
                        height: "40px",
                        "& .MuiInputBase-input": {
                          height: "40px",
                          boxSizing: "border-box",
                        },
                      }}
                      startAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                      name="ccFee"
                      type="number"
                      value={data.payment_data.fee_percent}
                      onChange={(e) =>
                        updatePaymentData("fee_percent", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item md={1.5}>
                    <Stack
                      direction="row"
                      alignItems={"center"}
                      justifyItems={"center"}
                      spacing={1}
                    >
                      <Button
                        sx={{
                          display: "flex",
                          flexShrink: 0,
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "4px",
                          fontSize: "12px",
                          minWidth: "30px",
                          height: "25px !important",
                        }}
                        onClick={() =>
                          updatePaymentData(
                            "amount_paid",
                            (
                              (getTotalAllocated(data) - getTotalCredit(data)) /
                              (1 - data.payment_data.fee_percent / 100)
                            ).toFixed(2)
                          )
                        }
                      >
                        match T.
                      </Button>
                    </Stack>
                    <Input
                      fullWidth
                      sx={{
                        height: "40px",
                        "& .MuiInputBase-input": {
                          height: "40px",
                          boxSizing: "border-box",
                        },
                        marginTop: 0.5,
                      }}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                      name="amountPaid"
                      type="number"
                      value={(
                        data.payment_data.amount_paid -
                        (data.payment_data.amount_paid *
                          data.payment_data.fee_percent) /
                          100
                      ).toFixed(2)}
                      readOnly
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Stack
                      direction="row"
                      alignItems={"center"}
                      justifyItems={"center"}
                      spacing={1}
                    >
                      <Typography variant="inputLabel" component="p">
                        Last 4 Digits
                      </Typography>
                    </Stack>

                    <Input
                      fullWidth
                      sx={{
                        height: "40px",
                        "& .MuiInputBase-input": {
                          height: "40px",
                          boxSizing: "border-box",
                        },
                      }}
                      name="ccDigits"
                      type="test"
                      value={data.payment_data.cc_digits}
                      onChange={(e) =>
                        updatePaymentData("cc_digits", e.target.value)
                      }
                    />
                  </Grid>
                </>
              )}
              {data.payment_data.method != "CREDIT_CARD" &&
                data.payment_data.method != "NONE" && (
                  <Grid item md={2}>
                    <Typography variant="inputLabel" component="p">
                      Reference #
                    </Typography>
                    <Input
                      fullWidth
                      sx={{
                        height: "40px",
                        "& .MuiInputBase-input": {
                          height: "40px",
                          boxSizing: "border-box",
                        },
                      }}
                      name="reference"
                      type="string"
                      value={data.payment_data.reference}
                      onChange={(e) =>
                        updatePaymentData("reference", e.target.value)
                      }
                    />
                  </Grid>
                )}
            </Grid>
          </Grid>
        </Grid>
        {(Object.keys(data.applied_credits).length > 0 ||
          data.credits_to_create.length > 0) && (
          <Grid md={12} paddingLeft={2} paddingRight={3} paddingTop={2}>
            <Table>
              <TableRow>
                <TableCell>Credit/Balance Type</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Memo</TableCell>
              </TableRow>
              {Object.keys(data.applied_credits).map((creditId) => (
                <CreditDisplay
                  val={data.applied_credits[creditId]}
                  disabled={true}
                  delItem={(creditId) => {
                    dispatch({
                      type: REDUCER_ACTIONS.EXLUDE_CREDIT,
                      id: creditId,
                    });
                  }}
                />
              ))}
              {data.credits_to_create.map((credit, index) => (
                <CreditInput
                  val={credit}
                  delItem={() => {
                    dispatch({
                      type: REDUCER_ACTIONS.REMOVE_CREDIT,
                      index: index,
                    });
                  }}
                  updateItem={(key, val) => {
                    dispatch({
                      type: REDUCER_ACTIONS.UPDATE_CREDIT,
                      index: index,
                      key,
                      value: val,
                    });
                  }}
                />
              ))}
            </Table>
          </Grid>
        )}
      </Grid>
      {/* Credits block*/}
    </Stack>
  );
}
