import api from "./api";
import { orderingToApi } from "./common";

export async function listNotifications(
  driver = false,
  page = 1,
  pageSize = 1,
  orderBy,
  search
) {
  console.log(orderBy, "orderBy");
  const orderingParam = orderBy.length
    ? `&ordering=${orderingToApi(orderBy)}`
    : "";
  const params = `page_size=${pageSize}&page=${page}${orderingParam}&${search}${driver ? "&driver=true" : "&driver=false"}`;
  console.log(params, "params");

  const result = await api.get(`/api/notifications/?${params}`);
  return result;
}

export async function notificationsCount(is_driver = "all") {
  const result = await api.get(
    `/api/notifications_count/?is_driver=${is_driver}`
  );
  return result;
}

export async function acknowledgeNotification(notificationId) {
  const result = await api.patch(
    `/api/notifications_acknowledge/${notificationId}/`
  );
  return result;
}
export async function acknowledgeAllNotification() {
  const result = await api.get(`/api/notifications_acknowledge_all/`);
  return result;
}
