import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import Separator from "../../../components/Separator";

function Reconciliations({ payment }) {
  if (payment.reconciliations.length == 0) {
    return <></>;
  }
  return (
    <>
      <Separator />
      <Typography fontSize={24} marginBottom={2}>
        Reconciliations
      </Typography>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography
                fontSize="16px"
                fontWeight={600}
                mb="0px"
                align="left"
              >
                Description
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                fontSize="16px"
                fontWeight={600}
                mb="0px"
                align="left"
              >
                Status
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                fontSize="16px"
                fontWeight={600}
                mb="0px"
                align="left"
              >
                Amount
              </Typography>
            </TableCell>
          </TableRow>
          {payment?.reconciliations?.map((rec) => (
            <TableRow>
              <TableCell>
                <Typography fontSize="16px" fontWeight={400} mb="0px">
                  {rec.notes}
                  {rec.method == "CREDIT_CARD_REFUND"
                    ? ` - Credit Card ${rec.cc_digits || ""}`
                    : ""}
                </Typography>
              </TableCell>
              <TableCell>
                {rec.group ? (
                  <Typography
                    onClick={() =>
                      (window.location.href = `/invoices/payments/${rec.group}/details`)
                    }
                  >
                    Used in payment #{rec.group}{" "}
                  </Typography>
                ) : (
                  <Typography>
                    {rec.amount + rec.fee > 0 ? "Open credit" : "Open balance"}
                  </Typography>
                )}
              </TableCell>
              <TableCell>
                <Typography fontSize="16px" fontWeight={400} mb="0px">
                  ${rec.amount + rec.fee}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Typography
        fontSize="16px"
        fontWeight={400}
        mb="0px"
        mt="12px"
        align="right"
        mr="12px"
      >
        <b>Total:</b> $
        {`${payment?.reconciliations
          .reduce((total, invoice) => total + Number(invoice.amount), 0)
          .toFixed(2)}`}
      </Typography>
    </>
  );
}

export default Reconciliations;
