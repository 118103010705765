import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { REDUCER_ACTIONS, getItemUnitPrice, getItemDicount } from "../config";
import {
  Add,
  AddCircleOutline,
  ArrowDropDown,
  CheckCircle,
  DeleteOutline,
  Done,
  HourglassBottom,
  LocalShipping,
  MoreHoriz,
  MoreHorizOutlined,
  MoreHorizRounded,
  NotInterested,
  PlusOne,
  Remove,
  RemoveCircle,
  RemoveCircleOutline,
  ShoppingBag,
  Visibility,
} from "@mui/icons-material";
import { listVariants } from "../../../distflowAPI/ordersApi";
import { useEffect, useState } from "react";
import Product from "../../manageAutoOrders/Product";
import RouteSelect from "../../../components/AsyncSelects/RouteSelect";

const sortBySku = (arr) => {
  return arr.sort((a, b) => {
    if (a.sku < b.sku) {
      return -1;
    }
    if (a.sku > b.sku) {
      return 1;
    }
    return 0;
  });
};
function ItemRoute({ data, dispatch, index, name }) {
  return (
    <RouteSelect
      disabled={!data.items[index].can_edit}
      extraStyles={{
        "& .MuiInputBase-root": {
          padding: "0 65px 0 9px",
        },
        "& .MuiInputBase-input": {
          padding: "2.5px 4px 2.5px 5px !important",
        },
        "& .MuiFormControl-root": {
          minHeight: "10px !important",
        },
      }}
      value={
        data.items[index][name]?.id
          ? {
              label: data.items[index][name]?.name || "-",
              id: data.items[index][name]?.id || null,
            }
          : null
      }
      onChange={(e, nv) => {
        console.log("nv is: ", nv);
        dispatch({
          type: REDUCER_ACTIONS.UPDATE_ITEM,
          name,
          index: index,
          value: nv ? { id: nv?.value, name: nv?.label } : null,
        });
      }}
    />
  );
}
function ItemQty({ data, dispatch, index, name }) {
  return (
    <Stack direction={"row"}>
      {/* <IconButton
        onClick={(e) =>
          dispatch({
            type: REDUCER_ACTIONS.UPDATE_ITEM,
            name: name,
            index: index,
            value: data.items[index][name] - 1,
          })
        }
        disabled={data.items[index][name] === 0}
        size="small"
      >
        <RemoveCircleOutline sx={{ fontSize: "12px" }} />
      </IconButton>*/}

      <Input
        type="number"
        value={data.items[index][name]}
        onChange={(e) =>
          dispatch({
            type: REDUCER_ACTIONS.UPDATE_ITEM,
            name,
            index: index,
            value: e.target.value,
          })
        }
        sx={{ maxWidth: 100, ".MuiInputBase-input": { fontSize: "12px" } }}
        disabled={!data.items[index].can_edit}
      />
      {/*<IconButton
        onClick={(e) =>
          dispatch({
            type: REDUCER_ACTIONS.UPDATE_ITEM,
            name,
            index: index,
            value: data.items[index][name] + 1,
          })
        }
        sx={{ padding: 0, margin: 0 }}
      >
        <AddCircleOutline sx={{ fontSize: "12px" }} />
      </IconButton> */}
    </Stack>
  );
}
function ItemUnitCost({ data, dispatch, index, name }) {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Typography sx={{ fontSize: "inherit" }}>$</Typography>
      <Input
        type="number"
        value={data.items[index][name]}
        onChange={(e) =>
          dispatch({
            type: REDUCER_ACTIONS.UPDATE_ITEM,
            name,
            index: index,
            value: e.target.value,
          })
        }
        sx={{ maxWidth: 100, ".MuiInputBase-input": { fontSize: "12px" } }}
        disabled={!data.items[index].can_edit}
      />
    </Stack>
  );
}
function ItemUnitPrice({ data, index, dispatch }) {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Input
        value={data.items[index].unit_price}
        sx={{ maxWidth: 100, ".MuiInputBase-input": { fontSize: "12px" } }}
        onChange={(e) => {
          dispatch({
            type: REDUCER_ACTIONS.UPDATE_ITEM,
            name: "unit_price",
            index,
            value: e.target.value,
          });
        }}
      />
    </Stack>
  );
}
function ItemDiscount({ data, index, dispatch }) {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Input
        value={data.items[index].discount_value}
        sx={{ maxWidth: 100, ".MuiInputBase-input": { fontSize: "12px" } }}
        onChange={(e) => {
          dispatch({
            type: REDUCER_ACTIONS.UPDATE_ITEM,
            name: "discount_value",
            index,
            value: e.target.value,
          });
        }}
      />
      <Select
        IconComponent={null}
        input={<Input sx={{ border: "none", padding: 0 }} disableUnderline />} // Use OutlinedInput and remove the border
        sx={{
          width: 20,
          height: 26, // Adjust the height as needed
          fontSize: "12px", // Adjust the font size of the select
          ".MuiSelect-select": {
            paddingRight: "unset", // Remove default right padding
            paddingLeft: "2px", // Add left padding for the text
          },
          ".MuiSelect-icon": {
            right: "0px",
          },
          backgroundColor: "transparent",
          border: 0,
          borderColor: "transparent",
        }}
        value={data.items[index].discount_type || "DOLLAR"}
        onChange={(e) => {
          dispatch({
            type: REDUCER_ACTIONS.UPDATE_ITEM,
            name: "discount_type",
            index: index,
            value: e.target.value,
          });
        }}
      >
        <MenuItem value={"PERCENT"}>%</MenuItem>
        <MenuItem value={"DOLLAR"}>$</MenuItem>
      </Select>
    </Stack>
  );
}
function InvoiceItem({ data, i, dispatch, orderItem, show }) {
  function getCostTotal() {
    return parseFloat(
      (orderItem.unit_cost || orderItem.cost) * orderItem.delivered_qty
    ).toFixed(2);
  }
  return (
    <TableRow key={i}>
      {show?.description?.value && (
        <TableCell>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            {orderItem.status == "PICKED_UP" && (
              <Tooltip title="Picked Up by driver">
                <LocalShipping color="success" style={{ maxHeight: 20 }} />
              </Tooltip>
            )}
            {orderItem.status == "CANCELED" && (
              <Tooltip title="Canceled">
                <NotInterested color="error" style={{ maxHeight: 20 }} />
              </Tooltip>
            )}
            {orderItem.status == "PENDING" && (
              <Tooltip title="Pending Approval">
                <HourglassBottom color="warning" style={{ maxHeight: 20 }} />
              </Tooltip>
            )}
            {orderItem.status == "APPROVED" && (
              <Tooltip title="Approved">
                <CheckCircle color="primary" style={{ maxHeight: 20 }} />
              </Tooltip>
            )}
            {orderItem.status == "DELIVERED" && (
              <Tooltip title="Delivered">
                <ShoppingBag color="success" style={{ maxHeight: 20 }} />
              </Tooltip>
            )}
            <Typography
              fontSize="12px"
              fontWeight={400}
              mb="0px"
              sx={{
                "::before": {
                  content: "''",
                  display: "inline-flex",
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  bgcolor: `${
                    orderItem.delivered_qty != orderItem.driver_delivered_qty
                      ? "red"
                      : "green"
                  }`,
                  marginRight: "4px",
                },
              }}
            >
              {orderItem.display_name}
              {" - "}
              {orderItem.status}
              {" - "}
              <b>
                {orderItem?.vendor?.name ||
                  orderItem?.vendor_details?.vendorName ||
                  JSON.stringify(orderItem)}
              </b>
            </Typography>
          </Stack>
        </TableCell>
      )}
      {show?.sku?.value && (
        <TableCell>
          <Typography fontSize="12px" fontWeight={400} mb="0px">
            {orderItem.variant_sku}
          </Typography>
        </TableCell>
      )}
      {show?.unit?.value && (
        <TableCell>
          <Typography fontSize="12px" fontWeight={400} mb="0px">
            {orderItem.unit}
          </Typography>
        </TableCell>
      )}
      {show?.unit_cost?.value && (
        <TableCell padding="0">
          <ItemUnitCost
            data={data}
            index={i}
            dispatch={dispatch}
            name="unit_cost"
          />
        </TableCell>
      )}
      {show?.billable_cost?.value && (
        <TableCell padding="0">
          <Typography fontSize="12px" fontWeight={400} mb="0px">
            $ {getCostTotal()}
          </Typography>
        </TableCell>
      )}
      {show?.unit_price?.value && (
        <TableCell>
          <ItemUnitPrice
            data={data}
            index={i}
            dispatch={dispatch}
            orderItem={orderItem}
          />
        </TableCell>
      )}
      {show?.discount?.value && (
        <TableCell>
          <ItemDiscount
            data={data}
            index={i}
            dispatch={dispatch}
            orderItem={orderItem}
          />
        </TableCell>
      )}
      {show?.price_w_discount?.value && (
        <TableCell>
          <Typography fontSize="12px" fontWeight={400} mb="0px">
            $
            {parseFloat(
              getItemUnitPrice(data.items[i]) - getItemDicount(data.items[i])
            ).toFixed(2)}
          </Typography>
        </TableCell>
      )}
      {show?.ordered_qty?.value && (
        <TableCell padding="0">
          <ItemQty data={data} index={i} dispatch={dispatch} name="quantity" />
        </TableCell>
      )}
      {show?.billable_qty?.value && (
        <TableCell padding="0">
          <ItemQty
            data={data}
            index={i}
            dispatch={dispatch}
            name="delivered_qty"
          />
        </TableCell>
      )}
      {show?.shipped_qty?.value && (
        <TableCell>
          <Typography fontSize="12px" fontWeight={600} mb="0px">
            {orderItem.driver_delivered_qty}
          </Typography>
        </TableCell>
      )}
      {show?.total?.value && (
        <TableCell>
          <Typography fontSize="12px" fontWeight={400} mb="0px">
            {" "}
            $
            {(
              orderItem.delivered_qty *
              (getItemUnitPrice(data.items[i]) - getItemDicount(data.items[i]))
            ).toFixed(2)}{" "}
          </Typography>
        </TableCell>
      )}
      {show?.route?.value && (
        <TableCell>
          <Typography fontSize="12px" fontWeight={400} mb="0px">
            <ItemRoute data={data} index={i} dispatch={dispatch} name="route" />
          </Typography>
        </TableCell>
      )}
      {show?.note?.value && (
        <TableCell padding="0">
          <Stack direction={"row"} alignItems={"center"}>
            <Input
              sx={{
                ".MuiInputBase-input": { fontSize: "12px" },
              }}
              value={orderItem.invoice_note}
              onChange={(e) => {
                dispatch({
                  type: REDUCER_ACTIONS.UPDATE_ITEM,
                  name: "can_edit",
                  index: i,
                  value: e.target.value,
                });
                dispatch({
                  type: REDUCER_ACTIONS.UPDATE_ITEM,
                  name: "invoice_note",
                  index: i,
                  value: e.target.value,
                });
              }}
              fullWidth
            />
          </Stack>
        </TableCell>
      )}
      <TableCell>
        {orderItem.status != "CANCELED" && (
          <Stack direction={"row"}>
            <IconButton
              color="error"
              onClick={() => {
                console.log("setting item i: ", i);
                dispatch({
                  type: REDUCER_ACTIONS.UPDATE_ITEM,
                  index: i,
                  name: "status",
                  value: "CANCELED",
                });
              }}
            >
              <DeleteOutline />
            </IconButton>
            <IconButton
              onClick={() => {
                window.open(
                  `/products/${orderItem?.product_id}/variants/${orderItem?.variant}`,
                  "_blank"
                );
                console.log("view item: ", orderItem);
              }}
            >
              <Visibility />
            </IconButton>
          </Stack>
        )}
        {orderItem.status == "CANCELED" && (
          <IconButton
            color="primary"
            onClick={() => {
              dispatch({
                type: REDUCER_ACTIONS.UPDATE_ITEM,
                index: i,
                name: "status",
                value: "DELIVERED",
              });
            }}
          >
            <Done />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
}

function getItemsRows() {
  let saved = null;
  try {
    const rowsRaw = window.localStorage.getItem("invoice_items_rows");
    saved = JSON.parse(rowsRaw);
  } catch {}
  return (
    saved || {
      description: {
        label: "Description",
        value: true,
      },
      sku: {
        label: "SKU",
        value: true,
      },
      unit: {
        label: "Unit",
        value: true,
      },
      unit_cost: {
        label: "Unit Cost",
        value: true,
      },
      billable_cost: {
        label: "Billable Cost",
        value: true,
      },
      unit_price: {
        label: "Unit Price",
        value: true,
      },
      discount: {
        label: "Discount",
        value: true,
      },
      price_w_discount: {
        label: "Price w. Discount",
        value: true,
      },
      ordered_qty: {
        label: "Ordered QTY",
        value: true,
      },
      billable_qty: {
        label: "Billable QTY",
        value: true,
      },
      shipped_qty: {
        label: "Shipped QTY",
        value: true,
      },
      total: {
        label: "Total",
        value: true,
      },
      note: {
        label: "Note",
        value: true,
      },
      route: {
        label: "Route",
        value: false,
      },
    }
  );
}
function InvoiceItems({ data, dispatch }) {
  const [show, setShow] = useState(getItemsRows());
  console.log("zhow is: ", show);
  useEffect(() => {
    window.localStorage.setItem("invoice_items_rows", JSON.stringify(show));
  }, [show]);
  return (
    <Stack>
      <Stack direction={"row"} spacing={1}>
        <Typography variant="h6">Order Items</Typography>
        <IconButton
          onClick={() =>
            dispatch({
              type: REDUCER_ACTIONS.SET_CONFIG_VAL,
              name: "addItem",
              value: true,
            })
          }
          color="success"
        >
          <AddCircleOutline />
        </IconButton>
        <IconButton
          color={data.config.showItemsDetailed ? "error" : "primary"}
          variant="contained"
          onClick={() =>
            dispatch({
              type: REDUCER_ACTIONS.SET_CONFIG_VAL,
              name: "showItemsDetailed",
              value: !data.config.showItemsDetailed,
            })
          }
        >
          <MoreHorizOutlined />
        </IconButton>
      </Stack>
      <Stack direction={"row"}>
        {Object.keys(show).map((k) => (
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={show[k].value}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
              onChange={(e) => {
                const newVal = { ...show };
                newVal[k].value = e.target.checked;
                setShow(newVal);
              }}
            />
            <Typography fontSize={10}>{show[k].label}</Typography>
          </Stack>
        ))}
      </Stack>
      <Table>
        <TableBody>
          <InvoiceTableHeader show={show} />
          {sortBySku(
            data.items
              .map((item, indexx) => {
                return {
                  ...item,
                  indexx,
                };
              })
              .filter((it) => !["CANCELED", "PENDING"].includes(it.status))
          ).map((orderItem, i) => {
            console.log("index: ", orderItem.indexx);
            console.log("new index: ", i);
            console.log("item is: ", orderItem);
            return (
              <InvoiceItem
                key={i}
                data={data}
                i={orderItem.indexx}
                dispatch={dispatch}
                orderItem={orderItem}
                show={show}
              />
            );
          })}
          {data.config.showItemsDetailed &&
            sortBySku(
              data.items
                .map((item, indexx) => {
                  return {
                    ...item,
                    indexx,
                  };
                })
                .filter((it) => ["CANCELED", "PENDING"].includes(it.status))
            ).map((orderItem, i) => (
              <InvoiceItem
                data={data}
                i={orderItem.indexx}
                dispatch={dispatch}
                orderItem={orderItem}
                show={show}
              />
            ))}
        </TableBody>
      </Table>
      <AddItem data={data} dispatch={dispatch} />
    </Stack>
  );
}

export default InvoiceItems;

function InvoiceTableHeader({ show }) {
  return (
    <TableRow>
      {show?.description?.value && (
        <TableCell width="15%">
          <Typography fontSize="12px" fontWeight={600} mb="0px">
            Description
          </Typography>
        </TableCell>
      )}
      {show?.sku?.value && (
        <TableCell width="5%">
          <Typography fontSize="12px" fontWeight={600} mb="0px">
            Sku
          </Typography>
        </TableCell>
      )}
      {show?.unit?.value && (
        <TableCell width="3%">
          <Typography fontSize="12px" fontWeight={600} mb="0px">
            Unit
          </Typography>
        </TableCell>
      )}
      {show?.unit_cost?.value && (
        <TableCell
          width="5%"
          style={{ whiteSpace: "normal", wordWrap: "break-word" }}
        >
          <Typography fontSize="12px" fontWeight={600} mb="0px">
            Unit Cost
          </Typography>
        </TableCell>
      )}
      {show?.billable_cost?.value && (
        <TableCell
          width="3%"
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          <Typography fontSize="12px" fontWeight={600} mb="0px">
            Billable Cost
          </Typography>
        </TableCell>
      )}
      {show?.unit_price?.value && (
        <TableCell
          width="3%"
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          <Typography fontSize="12px" fontWeight={600} mb="0px">
            Unit Price
          </Typography>
        </TableCell>
      )}
      {show?.discount?.value && (
        <TableCell width="3%">
          <Typography fontSize="12px" fontWeight={600} mb="0px">
            Discount
          </Typography>
        </TableCell>
      )}
      {show?.price_w_discount?.value && (
        <TableCell
          width="3%"
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          <Typography fontSize="12px" fontWeight={600} mb="0px">
            Price w.disc
          </Typography>
        </TableCell>
      )}
      {show?.ordered_qty?.value && (
        <TableCell
          width="3%"
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          <Typography fontSize="12px" fontWeight={600} mb="0px">
            Ordered Qty
          </Typography>
        </TableCell>
      )}
      {show?.billable_qty?.value && (
        <TableCell
          width="3%"
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          <Typography fontSize="12px" fontWeight={600} mb="0px">
            Billable Qty
          </Typography>
        </TableCell>
      )}
      {show?.shipped_qty?.value && (
        <TableCell
          width="3%"
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          <Typography fontSize="12px" fontWeight={600} mb="0px">
            Shipped Qty
          </Typography>
        </TableCell>
      )}
      {show?.total?.value && (
        <TableCell width="5%">
          <Typography fontSize="12px" fontWeight={600} mb="0px">
            Total
          </Typography>
        </TableCell>
      )}
      {show?.route?.value && (
        <TableCell width="10%">
          <Typography fontSize="12px" fontWeight={600} mb="0px">
            Route
          </Typography>
        </TableCell>
      )}
      {show?.note?.value && (
        <TableCell width="25%">
          <Typography fontSize="12px" fontWeight={600} mb="0px">
            Note
          </Typography>
        </TableCell>
      )}
      <TableCell width="3%">
        <Typography fontSize="12px" fontWeight={600} mb="0px">
          Actions
        </Typography>
      </TableCell>
    </TableRow>
  );
}

function AddItem({ data, dispatch }) {
  const [errors, setErrors] = useState({});
  const [variants, setVariants] = useState([]);
  const [totalPages, setTotalPages] = useState(2);
  const [productsPage, setProductsPage] = useState(1);
  const [filterVariant, setFilterVariant] = useState("");
  function updateVariantsList() {
    if (errors.length) setErrors([]);

    if (data.order.account && data.order.account.id) {
      listVariants(
        10,
        productsPage,
        [
          { value: data.order.account.id, column: "accountId" },
          { value: filterVariant, column: "search" },
        ],
        null
      ).then((res) => {
        const newVariants = res.results.map((v) => {
          const item = {
            ...v,
            label: v.name,
            ordered: false,
            ordered_pc: false,
            vendor_details: {
              vendorName: v.vendor,
            },
          };
          return item;
        });
        setVariants(newVariants);
        setTotalPages(Math.ceil(res.count / 10));
      });
    } else {
      setVariants([]);
    }
  }
  function setItemToAdd(d) {
    dispatch({
      type: REDUCER_ACTIONS.SET_CONFIG_VAL,
      name: "item_to_add",
      value: d.itemToAdd,
    });
  }
  useEffect(() => {
    if (data.order.account.id && data.order.fulfillment_date) {
      updateVariantsList();
    }
  }, [filterVariant]);
  function canAdd(item) {
    const currentItems = data.items.map((item) => item.sku);
    if (currentItems.includes(item.sku)) {
      if (!item.customizable) {
        return false;
      }
    }
    return true;
  }
  return (
    <Dialog open={data.config.addItem}>
      <DialogTitle></DialogTitle>
      <DialogContent>
        <Stack padding={2} spacing={1}>
          <TextField
            label="Search"
            value={filterVariant}
            onChange={(e) => setFilterVariant(e.target.value)}
          />
          <Box overflow={"auto"} maxHeight={250}>
            <Stack spacing={1}>
              {variants
                .filter((v) => canAdd(v))
                .map((item, index) => (
                  <Product
                    parent
                    item={item}
                    account={data.order.account}
                    // add item modal
                    customAdd={(data) =>
                      setItemToAdd({ itemToAdd: data, index })
                    }
                    showView
                  />
                ))}
            </Stack>
          </Box>
        </Stack>
        <AddItemQty data={data} dispatch={dispatch} />
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button
            onClick={() => {
              dispatch({
                type: REDUCER_ACTIONS.SET_CONFIG_VAL,
                name: "addItem",
                value: false,
              });
            }}
            variant="contained"
            color="error"
            sx={{ maxHeight: 30 }}
          >
            Cancel
          </Button>
          <Button variant="contained" color="success" sx={{ maxHeight: 30 }}>
            Add
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

function AddItemQty({ data, dispatch }) {
  const [qty, setQty] = useState(1);
  console.log("item to add add is: ", data.config.item_to_add);
  if (!data.config.item_to_add) {
    return <></>;
  }
  function addItem() {
    const payload = {
      vendor_details: data.config.item_to_add.item.vendor_details,
      name: data.config.item_to_add.item.name,
      variant: data.config.item_to_add.item.id,
      quantity: qty,
      delivered_qty: qty,
      unit_price: 0,
      discount_value: 0,
      discount_type: "DOLLAR",
      sku: data.config.item_to_add.item.sku,
      invoice_note: "",
      display_name: data.config.item_to_add.item.name,
      variant_sku: data.config.item_to_add.item.sku,
      unit: data.config.item_to_add.item.sold_by,
      unit_price: parseFloat(data.config.item_to_add.item?.price?.price || 0),
      driver_delivered_qty: 0,
      status: "DELIVERED",
      cost: data.config.item_to_add.item.cost,
    };
    dispatch({
      type: REDUCER_ACTIONS.ADD_ITEM,
      payload,
    });
    dispatch({
      type: REDUCER_ACTIONS.SET_CONFIG_VAL,
      name: "item_to_add",
      value: false,
    });
    dispatch({
      type: REDUCER_ACTIONS.SET_CONFIG_VAL,
      name: "addItem",
      value: false,
    });
  }

  return (
    <Dialog open={data.config.item_to_add}>
      <DialogTitle>
        <Typography>Add: {data.config.item_to_add.item.name}</Typography>
      </DialogTitle>
      <DialogContent>
        <Stack>
          <Typography>Qty</Typography>
          <Input value={qty} onChange={(e) => setQty(e.target.value)} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} spacing={1}>
          <Button
            onClick={() =>
              dispatch({
                type: REDUCER_ACTIONS.SET_CONFIG_VAL,
                name: "item_to_add",
                value: false,
              })
            }
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button variant="contained" color="success" onClick={() => addItem()}>
            Add
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
